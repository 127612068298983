import { useLocation } from "react-router-dom";
import MarkEmailUnreadIcon from "@mui/icons-material/MarkEmailUnread";
import { Avatar, Divider, Typography } from "@mui/material";

// Auth notification helper component
export const AuthNotification = () => {
  // App location info
  const location = useLocation();

  // Render the DOM
  return (
    <>
      <Avatar sx={{ m: 1 }}>
        <MarkEmailUnreadIcon />
      </Avatar>
      <Typography component="h5" variant="h5" color={"green"} gutterBottom>
        {location.state.title}
        <Divider orientation="horizontal" />
      </Typography>

      <Typography variant="body1" gutterBottom>
        {location.state.message}
      </Typography>

      <Typography variant="body1">
        You can now safely close this tab.
      </Typography>
    </>
  );
};
