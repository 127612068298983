import PasswordIcon from "@mui/icons-material/Password";
import { CircularProgress } from "@mui/material";
import { Avatar, Button, TextField, Typography, Stack } from "@mui/material";
import { useState } from "react";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import {
  isValidPassword,
  isValidRepeatPassword,
} from "../../common/validationHelpers";
import { useApi } from "../ApiProvider/ApiProvider";
import { PasswordHelperText } from "./LoginForm";
import { useAppState } from "../AppStateProvider/AppStateProvider";

// Reset password form helper component
export const PasswordResetForm = () => {
  // Get auth context
  const { authApi, setSessionState } = useApi();

  // Get notification context
  const { setToast } = useAppState();

  // Get notification context
  const navigate = useNavigate();

  // Form field touched state
  const [touched, setTouched] = useState({
    password: false,
    repeatPassword: false,
  });

  // Loading state
  const [loading, setLoading] = useState(false);

  // Password field state
  const [password, setPassword] = useState("");
  // Repeat password field state
  const [repeatPassword, setRepeatPassword] = useState("");

  // Get query params
  const [searchParams] = useSearchParams();
  // Get the token from query params
  const token = searchParams.get("token");
  // Redirect to login if no token is present
  if (!token) return <Navigate to={"/auth/login"} />;

  // On-change handler
  const handleChange = (event, stateSetter) => {
    stateSetter(event.target.value);
  };

  // Handle the form submission
  const onSubmit = async () => {
    try {
      // Set the loading indicator
      setLoading(true);

      // Submit the request
      const res = await authApi.passwordReset({
        password: password,
        token: token,
      });

      // Set the profile state
      setSessionState(res?.Data);

      // Show a toast
      setToast({
        message: "Login successful",
        type: "success", // success / error / warning / info / default
      });
    } catch (e) {
      setToast({
        message: e && e.message ? e.message : "Action failed",
        type: "error", // success / error / warning / info / default
      });
    }

    // Reset the loading indicator
    setLoading(false);
  };

  // Form validation helper
  const isFormInvalid = () => {
    return (
      isValidPassword(password) &&
      isValidRepeatPassword(password, repeatPassword)
    );
  };

  return (
    <>
      <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
        <PasswordIcon />
      </Avatar>
      <Typography component="h5" variant="h5">
        Reset password
      </Typography>
      <Stack
        component="form"
        sx={{
          marginTop: 1,
          width: "30ch",
        }}
        spacing={2}
        autoComplete="off"
      >
        <TextField
          onBlur={() => {
            setTouched((v) => ({ ...v, password: true }));
          }}
          error={touched.password && !isValidPassword(password)}
          helperText={<PasswordHelperText />}
          id="login-password"
          label="New password"
          variant="outlined"
          margin="normal"
          type={"password"}
          onChange={(e) => handleChange(e, setPassword)}
        />
        <TextField
          onBlur={() => {
            setTouched((v) => ({ ...v, repeatPassword: true }));
          }}
          error={
            touched.repeatPassword &&
            !isValidRepeatPassword(password, repeatPassword)
          }
          helperText="The two password fields must be valid and equal"
          id="login-repeat-password"
          label="Repeat password"
          variant="outlined"
          margin="normal"
          type={"password"}
          onChange={(e) => handleChange(e, setRepeatPassword)}
        />
        <Button
          variant="outlined"
          size="large"
          onClick={() => onSubmit()}
          disabled={loading || !isFormInvalid()}
        >
          {loading ? (
            <span>
              <CircularProgress size={15} />
            </span>
          ) : (
            <span>Submit</span>
          )}
        </Button>
        <Button
          variant="text"
          size="small"
          onClick={() => navigate("/auth/login", { replace: true })}
        >
          Return to login
        </Button>
      </Stack>
    </>
  );
};
