import { httpGET, httpPOST } from "./httpHelpers";

// Read from the Bloomberg indices cache
export async function httpGetBloombergIndices(limit, offset) {
  return httpGET(
    `/feeds/investec/bloomberg/indices?limit=${encodeURIComponent(
      limit
    )}&offset=${encodeURIComponent(offset)}`
  );
}

// Flush the current Bloomberg indices data
export async function httpFlushBloombergIndices() {
  return httpPOST(`/feeds/investec/bloomberg/indices/flush`, null);
}

// Read from the Bloomberg news cache
export async function httpGetBloombergNews(limit, offset) {
  return httpGET(
    `/feeds/investec/bloomberg/news?limit=${encodeURIComponent(
      limit
    )}&offset=${encodeURIComponent(offset)}`
  );
}

// Read from the Investec focus feeds cache
export async function httpGetInvestecFocusFeeds(limit, offset) {
  return httpGET(
    `/feeds/investec/focusfeeds?limit=${encodeURIComponent(
      limit
    )}&offset=${encodeURIComponent(offset)}`
  );
}
