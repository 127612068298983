import { httpGET, httpPOST } from "./httpHelpers";

// Get a batch of users from the server
export async function httpGetUsers(limit, offset) {
  return httpGET(
    `/users?limit=${encodeURIComponent(limit)}&offset=${encodeURIComponent(
      offset
    )}`
  );
}

// Update a batch of users
export async function httpUpdateUsers(payload) {
  return httpPOST("/users", payload);
}

// Revoke a given user's token, invalidating their active session
export async function httpRevokeUserToken(payload) {
  return httpPOST("/users/revoke", payload);
}

// Reset a user's profile state
export async function httpResetUserProfile(payload) {
  return httpPOST("/users/reset", payload);
}

/** User roles */

/**
 *
 * @param {*} limit
 * @param {*} offset
 * @returns
 */

// Get a batch of roles from the server
export async function httpGetUserRoles(limit, offset) {
  return httpGET(
    `/users/roles?limit=${encodeURIComponent(
      limit
    )}&offset=${encodeURIComponent(offset)}`
  );
}

// Update a batch of roles
export async function httpUpdateUserRoles(payload) {
  return httpPOST("/users/roles", payload);
}

// Reinstate a given role
export async function httpReinstateUserRole(payload) {
  return httpPOST("/users/roles/reinstate", payload);
}

/** User permissions */

/**
 *
 * @param {*} limit
 * @param {*} offset
 * @returns
 */

// Get a batch of permissions from the server
export async function httpGetUserPermissions(limit, offset) {
  return httpGET(
    `/users/permissions?limit=${encodeURIComponent(
      limit
    )}&offset=${encodeURIComponent(offset)}`
  );
}

// Update a batch of permissions
export async function httpUpdateUserPermissions(payload) {
  return httpPOST("/users/permissions", payload);
}

// Reinstate a given permission
export async function httpReinstateUserPermission(payload) {
  return httpPOST("/users/permissions/reinstate", payload);
}
