import "./Sidebar.css";
import { useApi } from "../ApiProvider/ApiProvider";
import { useEffect, useRef, useState } from "react";
import { RichTreeView } from "@mui/x-tree-view/RichTreeView";
import { TreeItem, treeItemClasses } from "@mui/x-tree-view/TreeItem";
import { styled, alpha } from "@mui/material/styles";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppState } from "../AppStateProvider/AppStateProvider";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import CloudIcon from "@mui/icons-material/Cloud";

const CustomTreeItem = styled(TreeItem)(({ theme }) => ({
  color:
    theme.palette.mode === "light"
      ? theme.palette.grey[800]
      : theme.palette.grey[200],

  [`& .${treeItemClasses.content}`]: {
    borderRadius: theme.spacing(0.5),
    padding: theme.spacing(0.5, 1),
    margin: theme.spacing(0.2, 0),
    [`& .${treeItemClasses.label}`]: {
      fontSize: "1rem",
      fontWeight: 500,
    },
  },
  [`& .${treeItemClasses.iconContainer}`]: {
    borderRadius: "50%",
    backgroundColor:
      theme.palette.mode === "light"
        ? alpha(theme.palette.primary.main, 0.25)
        : theme.palette.primary.dark,
    color: theme.palette.mode === "dark" && theme.palette.primary.contrastText,
    padding: theme.spacing(0, 1.2),
  },
  [`& .${treeItemClasses.groupTransition}`]: {
    marginLeft: 15,
    paddingLeft: 18,
    borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
  },
}));

// Sidebar nav component
export default function Sidebar() {
  // Get various APIs to manage auth, app and session state variables
  const { authApi, appState, setSessionState } = useApi();
  // Get app state
  const { canNavigate, setToast } = useAppState();
  // App location info
  const { pathname } = useLocation();
  // Get notification context
  const navigate = useNavigate();

  const navTree = useRef(appState.routes);

  // List of expanded treeview items
  const [expandedItems, setExpandedItems] = useState([]);
  // List of selected treeview items
  const [selectedItems, setSelectedItems] = useState([]);

  // On mount/unmount, run this
  useEffect(() => {
    // Get all possible treeview nodes to expand
    const getAllItemsWithChildrenItemIds = () => {
      const itemIds = [];
      const registerItemId = (item) => {
        if (item.children?.length) {
          itemIds.push(item.id);
          item.children.forEach(registerItemId);
        }
      };

      navTree.current.forEach(registerItemId);

      return itemIds;
    };

    // Expand all by default
    setExpandedItems(getAllItemsWithChildrenItemIds());
  }, []);

  // Whenever the path changes, update the selected item to the active route
  useEffect(() => {
    setSelectedItems([pathname]);
  }, [pathname]);

  // Handle a treeview expanded items change event
  const handleExpandedItemsChange = (event, itemIds) => {
    setExpandedItems(itemIds);
  };

  // Handle a treeview selection change and navigate to the target route
  const handleSelectedItemsChange = (event, id) => {
    // Exit if not a valid route
    if (!(id + "").includes("/")) return;

    // Set the selected menu item and navigate to the route
    setSelectedItems([id]);
    navRoute(id);
  };

  // Navigate to given route
  const navRoute = (route) => {
    // Block navigation if necessary
    if (!canNavigate) {
      return setToast({
        message: "Please commit or undo pending changes before navigating",
        type: "error", // success / error / warning / info / default
      });
    }
    // Only navigate if not already on the desired page
    if (pathname !== route) {
      return navigate(route);
    }
  };

  // Handle a logout
  const navLogout = async () => {
    try {
      setSessionState(null);
      await authApi.logout();
    } catch (e) {
      throw new Error(e && e.message ? e.message : "Oops!");
    }
  };

  return (
    appState.user && (
      <div className="sidebar container flex-column">
        <div style={{ flex: 7.5 }}>
          <Button variant="text" style={{ marginTop: "5%" }}>
            <CloudIcon sx={{ display: { xs: "none", md: "flex" }, mr: 1 }} />
            <Typography
              variant="h6"
              noWrap
              sx={{
                mr: 2,
                display: { xs: "none", md: "flex" },
                fontFamily: "monospace",
                fontWeight: 700,
                letterSpacing: ".3rem",
                color: "inherit",
                textDecoration: "none",
              }}
            >
              Ethniks Cloud
            </Typography>
          </Button>
        </div>
        <div className="nav-tree" style={{ flex: 100 }}>
          <RichTreeView
            slots={{ item: CustomTreeItem }}
            onSelectedItemsChange={handleSelectedItemsChange}
            onExpandedItemsChange={handleExpandedItemsChange}
            selectedItems={selectedItems}
            expandedItems={expandedItems}
            items={navTree.current}
          ></RichTreeView>
        </div>
        <div style={{ flex: 7.5 }}>
          <Button variant="outlined" onClick={() => navLogout()}>
            Sign out
          </Button>
        </div>
      </div>
    )
  );
}
