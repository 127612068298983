import { Navigate } from "react-router-dom";
import { AppLayout } from "../AppLayout/AppLayout";
import { useApi } from "../ApiProvider/ApiProvider";

// A helper component for unprotected(no auth) parent components
export function UnprotectedLayout() {
  // Get user details
  const { appState } = useApi();

  // If a user is logged in, redirect
  if (appState.user) {
    return <Navigate to="/dashboard" replace />;
  }

  // Render the DOM
  return <AppLayout />;
}
