import { httpGET, httpPOST } from "./httpHelpers";

// Get a batch of users from the server
export async function httpGetDevices(limit, offset) {
  return httpGET(
    `/devices?limit=${encodeURIComponent(limit)}&offset=${encodeURIComponent(
      offset
    )}`
  );
}

// Update a batch of devices
export async function httpUpdateDevices(payload) {
  return httpPOST("/devices/", payload);
}

// Revoke a given device's token
export async function httpRevokeDeviceToken(payload) {
  return httpPOST("/devices/revoke", payload);
}

// Revoke a given device's token
export async function httpReinstateDevice(payload) {
  return httpPOST("/devices/reinstate", payload);
}

// Reset a device's profile state
export async function httpGenerateDeviceToken(payload) {
  return httpPOST("/devices/token", payload);
}
