import { Navigate } from "react-router-dom";
import { AppLayout } from "../AppLayout/AppLayout";
import { useApi } from "../ApiProvider/ApiProvider";

// A helper component for protected parent components
export function ProtectedLayout() {
  // Get user details
  const { appState } = useApi();

  // Redirect to /auth if no user is logged in
  if (!appState.user) {
    return <Navigate to="/auth/login" replace />;
  }

  // Render the DOM
  return <AppLayout />;
}

// // Standalone protected route
// export function ProtectedRoute({ children }) {
//   const { user } = useApi();
//   // Redirect to /auth if no user is logged in
//   if (!user) {
//     return <Navigate to="/devices" />;
//   }
//   return children;
// }
