import CrudGrid from "../CrudGrid/CrudGrid";
import { useApi } from "../ApiProvider/ApiProvider";
import { GridActionsCellItem } from "@mui/x-data-grid";
import Tooltip from "@mui/material/Tooltip";
import LockResetIcon from "@mui/icons-material/LockReset";
import KeyIcon from "@mui/icons-material/Key";
import { useEffect, useState } from "react";
import ApiKeyDialog from "./ApiKeyDialog";
import { useAppState } from "../AppStateProvider/AppStateProvider";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

// TODO
// Confirmation dialog before regenerating a key for a single token (slide, puzzle, anything)
// Fix create -> fail -> close dialog -> edit / delete -> error
// Fix update on delete issue in auth service
// Do not fetch deleted rows from DB for roles / perms etc

// Tokens component
export default function Tokens() {
  // Get user details
  const { tokenApi } = useApi();
  // Get notification context
  const { setToast } = useAppState();

  // This state refreshes the view
  const [refresh, setRefresh] = useState(false);

  // Generated API key dialog states
  const [showApiKeyDialog, setShowApiKeyDialog] = useState(false);
  const [newTokens, setNewTokens] = useState(null);

  // Key gen confirmation states
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [tokenId, setTokenId] = useState(null);

  // Close API key dialog handler // --- fix
  const handleCloseApiKeysDialog = () => {
    setShowApiKeyDialog(false);
    setNewTokens(null);
  };

  // Run this effect when new tokens are set
  useEffect(() => {
    setShowApiKeyDialog(newTokens?.length > 0);
  }, [newTokens]);

  // Save changes to the grid
  const onSave = async (rows) => {
    // Call the update handler
    const res = await tokenApi.update(rows);
    // Exit if no keys were returned
    if (res?.Data?.Records?.length <= 0) return;
    // Update the list of new tokens
    setNewTokens(res?.Data?.Records || []);
    setRefresh((v) => !v);
  };

  // Validate any created or edited user model
  const validateModel = (model) => {
    // Create the validity flag
    var isValid = true;

    // Validate name
    if (!model.FriendlyName || model.FriendlyName.length < 1) isValid = false;
    if (!model.Organisation || model.Organisation.length < 1) isValid = false;

    // Return the result
    return isValid;
  };

  // Handle a device reinstatement
  const onReinstate = async (id) => {
    try {
      await tokenApi.reinstate({ uuid: id });
      setRefresh((v) => !v);
    } catch (e) {
      // Show an error message
      setToast({
        message: e && e.message ? e.message : "Action failed",
        type: "error", // success / error / warning / info / default
      });
    }
  };

  // Handle a key generation
  const onGenerate = (id) => {
    setShowConfirmDialog(true);
    setTokenId(id);
  };

  // Close confirmation dialog handler
  const handleCloseConfirmDialog = async (id) => {
    // Exit if the token ID is invalid or unset
    if (id && id.length && id === tokenId) {
      try {
        // Generate the new API key
        const res = await tokenApi.generate({ uuid: id });
        // Exit if no tokens were returned
        if (res?.Data?.Records?.length <= 0) return;
        // Update the list of new tokens
        setNewTokens(res?.Data?.Records || []);
      } catch (e) {
        // Show a toast
        setToast({
          message: e && e.message ? e.message : "Action failed",
          type: "error", // success / error / warning / info / default
        });
      }
    }

    // Reset the confirmation dialog and token ID
    setShowConfirmDialog(false);
    setTokenId(null);
  };

  // Define custom actions for the grid
  const getCustomActions = (data, id) => {
    var options = [];

    // Only active tokens can be regenerated
    if (data?.Status !== "deleted")
      options.push(
        <GridActionsCellItem
          icon={
            <Tooltip title="Generate">
              <KeyIcon />
            </Tooltip>
          }
          label="Generate"
          onClick={() => onGenerate(id)}
          color="inherit"
        />
      );

    // Only deleted items can be reinstated
    if (data?.Status === "deleted")
      options.push(
        <GridActionsCellItem
          icon={
            <Tooltip title="Reinstate">
              <LockResetIcon />
            </Tooltip>
          }
          label="Reinstate"
          onClick={() => onReinstate(id)}
          color="inherit"
        />
      );

    return options;
  };

  // Define the columns for the users grid
  const columns = [
    {
      field: "FriendlyName",
      headerName: "FriendlyName",
      flex: 2,
      minWidth: 100,
      editable: true,
      preProcessEditCellProps: (params) => {
        const hasError = !params.props.value || params.props.value.length < 1;
        return { ...params.props, error: hasError };
      },
      validate: (v) => {
        return !!(v && v.length > 0);
      },
    },
    {
      field: "Organisation",
      headerName: "Organisation",
      flex: 2,
      minWidth: 100,
      editable: true,
      preProcessEditCellProps: (params) => {
        const hasError = !params.props.value || params.props.value.length < 1;
        return { ...params.props, error: hasError };
      },
      validate: (v) => {
        return !!(v && v.length > 0);
      },
    },
    {
      field: "Attributes",
      headerName: "Attributes",
      flex: 2,
      minWidth: 100,
      editable: true,
      preProcessEditCellProps: (params) => {
        const hasError = !params.props.value || params.props.value.length < 1;
        return { ...params.props, error: hasError };
      },
    },
    {
      field: "CustomId",
      headerName: "Custom ID",
      flex: 2,
      minWidth: 100,
      editable: true,
      preProcessEditCellProps: (params) => {
        const hasError = !params.props.value || params.props.value.length < 1;
        return { ...params.props, error: hasError };
      },
      validate: (v) => {
        return !!(v && v.length > 0);
      },
    },
    {
      field: "Status",
      headerName: "Status",
      flex: 2,
      minWidth: 100,
      editable: false,
    },
    {
      field: "LastSeen",
      headerName: "LastSeen",
      flex: 2,
      minWidth: 100,
      editable: false,
    },
    {
      field: "ExpiresAt",
      headerName: "ExpiresAt",
      flex: 2,
      minWidth: 100,
      editable: false,
    },
  ];

  // Render the DOM
  return (
    <div className="tokens container">
      <CrudGrid
        customColumns={columns}
        getCustomActionsCb={getCustomActions}
        fetchDataCb={tokenApi.get}
        onSaveCb={onSave}
        validateRowCb={validateModel}
        disableExport
        forceRefresh={refresh}
      />
      <ApiKeyDialog
        open={showApiKeyDialog}
        tokens={newTokens}
        onCloseCb={handleCloseApiKeysDialog}
      />
      <ConfirmationDialog
        open={showConfirmDialog}
        tokenId={tokenId}
        onCloseCb={handleCloseConfirmDialog}
      />
    </div>
  );
}

// Confirmation dialog befor generating API keys
function ConfirmationDialog(props) {
  // Destruct props
  const { open, tokenId, onCloseCb } = props;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Dialog fullScreen={fullScreen} open={open} onClose={() => onCloseCb()}>
      <DialogTitle>
        {"Are you sure you wish to generate a API key?"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          This will overwrite existing keys and revoke their access.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={() => onCloseCb()}>
          Cancel
        </Button>
        <Button onClick={() => onCloseCb(tokenId)}>Confirm</Button>
      </DialogActions>
    </Dialog>
  );
}
