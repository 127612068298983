import { httpGET, httpPOST } from "./httpHelpers";

// Retrieve token usage data
export async function httpGetTokenUsage() {
  return httpGET(`/tokens/usage`);
}

// Get a batch of Tokens from the server
export async function httpGetTokens(limit, offset) {
  return httpGET(
    `/tokens?limit=${encodeURIComponent(limit)}&offset=${encodeURIComponent(
      offset
    )}`
  );
}

// Update a batch of tokens
export async function httpUpdateTokens(payload) {
  return httpPOST("/tokens", payload);
}

// Revoke a given token
export async function httpRevokeToken(payload) {
  return httpPOST("/tokens/revoke", payload);
}

// Revoke a given token
export async function httpReinstateToken(payload) {
  return httpPOST("/tokens/reinstate", payload);
}

// Generate a new API token
export async function httpGenerateToken(payload) {
  return httpPOST("/tokens/generate", payload);
}

/** Token permissions */

/**
 *
 * @param {*} limit
 * @param {*} offset
 * @returns
 */

// Get a batch of permissions from the server
export async function httpGetTokenPermissions(limit, offset) {
  return httpGET(
    `/tokens/permissions?limit=${encodeURIComponent(
      limit
    )}&offset=${encodeURIComponent(offset)}`
  );
}

// Update a batch of permissions
export async function httpUpdateTokenPermissions(payload) {
  return httpPOST("/tokens/permissions", payload);
}

// Reinstate a given permission
export async function httpReinstateTokenPermission(payload) {
  return httpPOST("/tokens/permissions/reinstate", payload);
}
