import CrudGrid from "../CrudGrid/CrudGrid";
import { useApi } from "../ApiProvider/ApiProvider";
import { GridActionsCellItem } from "@mui/x-data-grid";
import Tooltip from "@mui/material/Tooltip";
import LockResetIcon from "@mui/icons-material/LockReset";
import { useState } from "react";

// Permissions component
export default function TokenPermissions() {
  // Get user details
  const { tokenPermApi } = useApi();

  // This state refreshes the view
  const [refresh, setRefresh] = useState(false);

  // Validate any created or edited user model
  const validateModel = (model) => {
    // Create the validity flag
    var isValid = true;

    // Validate name
    if (!model.Name || model.Name.length < 1) isValid = false;

    // Return the result
    return isValid;
  };

  // Handle a device reinstatement
  const onReinstate = (id) => {
    tokenPermApi.reinstate({ uuid: id });
    setRefresh((v) => !v);
  };

  // Define custom actions for the grid
  const getCustomActions = (data, id) => {
    var options = [];

    // Only deleted items can be reinstated
    if (data?.Status === "deleted")
      options.push(
        <GridActionsCellItem
          icon={
            <Tooltip title="Reinstate">
              <LockResetIcon />
            </Tooltip>
          }
          label="Reinstate"
          onClick={() => onReinstate(id)}
          color="inherit"
        />
      );

    return options;
  };

  // Define the columns for the users grid
  const columns = [
    {
      field: "Name",
      headerName: "Name",
      flex: 2,
      minWidth: 100,
      editable: true,
      preProcessEditCellProps: (params) => {
        const hasError = !params.props.value || params.props.value.length < 1;
        return { ...params.props, error: hasError };
      },
      validate: (v) => {
        return !!(v && v.length > 0);
      },
    },
    {
      field: "Status",
      headerName: "Status",
      flex: 2,
      minWidth: 100,
      editable: false,
    },
  ];

  // Render the DOM
  return (
    <div className="users container">
      <CrudGrid
        customColumns={columns}
        getCustomActionsCb={getCustomActions}
        fetchDataCb={tokenPermApi.get}
        onSaveCb={tokenPermApi.update}
        validateRowCb={validateModel}
        disableExport
        forceRefresh={refresh}
      />
    </div>
  );
}
