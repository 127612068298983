// An API request wrapper
async function httpRequest(method, url, payload, contentType) {
  // Get the CSRF token
  const csrfToken = getCookie("csrf_token");

  // Compile the request options
  const requestOptions = {
    method: method.toUpperCase(),
    headers: {
      "X-CSRF-Token": csrfToken,
    },
    body: contentType ? payload : JSON.stringify(payload),
    credentials: "same-origin",
  };

  console.log(`/portal${url}`);

  // Execute the call - returning the result
  return fetch(`/portal${url}`, requestOptions);
}

// HTTP 'POST' helper
export async function httpPOST(url, payload, contentType) {
  return httpRequest("POST", url, payload, contentType);
}

// HTTP 'PUT' helper
export async function httpPUT(url, payload, contentType) {
  return httpRequest("PUT", url, payload, contentType);
}

// HTTP 'GET' helper
export async function httpGET(url) {
  return httpRequest("GET", url);
}

// HTTP 'DELETE' helper
export async function httpDELETE(url) {
  return httpRequest("DELETE", url);
}

// Get a cookie by name
function getCookie(key) {
  var b = document.cookie.match("(^|;)\\s*" + key + "\\s*=\\s*([^;]+)");
  return b ? b.pop() : "";
}
