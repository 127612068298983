import "./CrudGrid.css";
import { useEffect, useState, useCallback, useRef } from "react";
import {
  DataGrid,
  GridActionsCellItem,
  GridCsvExportMenuItem,
  gridFilteredSortedRowIdsSelector,
  GridRowModes,
  GridToolbarContainer,
  GridToolbarExportContainer,
  gridVisibleColumnFieldsSelector,
  useGridApiContext,
} from "@mui/x-data-grid";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import PropTypes from "prop-types";
import { Button, Typography, useMediaQuery, useTheme } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import UndoIcon from "@mui/icons-material/Undo";
import { v4 as uuidv4 } from "uuid";
import { useAppState } from "../AppStateProvider/AppStateProvider";
import { exportBlob } from "../../common/utility";
import ConfirmChangesDialog from "./ConfirmChangesDialog";

// Helper function to convert grid data to a JSON string
const getJson = (apiRef) => {
  // Select rows and columns
  const filteredSortedRowIds = gridFilteredSortedRowIdsSelector(apiRef);
  const visibleColumnsField = gridVisibleColumnFieldsSelector(apiRef);

  // Format the data. Here we only keep the value
  const data = filteredSortedRowIds.map((id) => {
    const row = {};
    visibleColumnsField.forEach((field) => {
      row[field] = apiRef.current.getCellParams(id, field).value;
    });
    return row;
  });

  // Stringify with some indentation
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/JSON/stringify#parameters
  return JSON.stringify(data, null, 2);
};

// Toolbar JSON export menu item
const JsonExportMenuItem = (props) => {
  // Destruct props
  const { hideMenu, filename } = props;

  // Get the grid API context
  const apiRef = useGridApiContext();

  return (
    <MenuItem
      onClick={() => {
        const jsonString = getJson(apiRef);
        const blob = new Blob([jsonString], {
          type: "text/json",
        });

        exportBlob(blob, `${filename}.json`);

        // Hide the export menu after the export
        hideMenu?.();
      }}
    >
      Export JSON
    </MenuItem>
  );
};

// Define JSON export menu props
JsonExportMenuItem.propTypes = {
  hideMenu: PropTypes.func,
};

// Custom toolbar export button
const ToolbarExportButton = (props) => {
  // Destruct props
  const { filename } = props;
  // Dated filename (dd-mm-yyyy)
  const exportFilename = `${(filename || "export").replace(
    / +/gi,
    "_"
  )}_${new Date().toJSON().slice(0, 10).split("-").reverse().join("-")}`;

  return (
    <GridToolbarExportContainer>
      <GridCsvExportMenuItem options={{ fileName: exportFilename }} />
      <JsonExportMenuItem filename={exportFilename} />
    </GridToolbarExportContainer>
  );
};

// A helper component for the grid's toolbar
function EditToolbar(props) {
  const {
    onAddRecordClickCb,
    onSaveChangesClickCb,
    onUndoChangesClickCb,
    onFlushDataClickCb,
    flush,
    canAddRecord,
    isDirty,
    filename,
    readOnly,
    disableExport,
  } = props;

  // Get theme context
  const theme = useTheme();
  // Calculate screen breakpoints
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  // Flush dialog open/close state
  const [flushDialogOpen, setFlushDialogOpen] = useState(false);

  // Handle a flush data click
  const onFlushDataClick = () => {
    setFlushDialogOpen(true);
  };

  // Handle a flush confirmation
  const onFlushConfirm = () => {
    // Call the given handler
    if (onFlushDataClickCb instanceof Function) onFlushDataClickCb();
    // Close the dialog
    setFlushDialogOpen(false);
  };

  // Dialog close handler
  const onFlushCancel = (event, reason) => {
    // Do not close for background clicks
    if (reason === "backdropClick") return;
    // Close the dialog
    setFlushDialogOpen(false);
  };

  // Handle an add record click
  const onAddRecordClick = () => {
    if (onAddRecordClickCb instanceof Function) onAddRecordClickCb();
  };

  // Handle a save changes click
  const onSaveChangesClick = () => {
    if (onSaveChangesClickCb instanceof Function) onSaveChangesClickCb();
  };

  // Handle an undo changes click
  const onUndoChangesClick = () => {
    if (onUndoChangesClickCb instanceof Function) onUndoChangesClickCb();
  };

  return (
    <>
      <Dialog
        open={flushDialogOpen}
        onClose={onFlushCancel}
        fullScreen={fullScreen}
        sx={{ "& .MuiDialog-paper": { width: "100%", maxHeight: 750 } }}
        disableEscapeKeyDown
      >
        <DialogTitle>
          <Typography sx={{ mt: 4, mb: 2 }} variant="body" color={"error"}>
            This action is irreversible
          </Typography>
        </DialogTitle>
        <DialogContent dividers>
          <Typography sx={{ mt: 4, mb: 2 }} variant="body1">
            Flushing data cannot be undone and may have severe side-effects in
            production. Are you sure you want to proceed?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={onFlushCancel}>Close</Button>
          <Button
            variant="outlined"
            size="large"
            onClick={onFlushConfirm}
            color="error"
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <GridToolbarContainer>
        {!readOnly && (
          <>
            <Button
              color="primary"
              startIcon={<AddIcon />}
              onClick={onAddRecordClick}
              disabled={!canAddRecord}
            >
              Add record
            </Button>
            <Button
              color="primary"
              startIcon={<SaveIcon />}
              onClick={onSaveChangesClick}
              disabled={!isDirty}
            >
              Save changes
            </Button>
            <Button
              color="primary"
              startIcon={<UndoIcon />}
              onClick={onUndoChangesClick}
              disabled={!isDirty}
            >
              Undo changes
            </Button>
          </>
        )}
        {!disableExport && <ToolbarExportButton filename={filename} />}
        {flush && (
          <Button
            variant="outlined"
            size="large"
            color="error"
            onClick={onFlushDataClick}
          >
            Flush
          </Button>
        )}
      </GridToolbarContainer>
    </>
  );
}

// Toolbar component proptype flags
EditToolbar.propTypes = {
  onAddRecordClickCb: PropTypes.func.isRequired,
  onSaveChangesClickCb: PropTypes.func.isRequired,
  onUndoChangesClickCb: PropTypes.func.isRequired,
  onFlushDataClickCb: PropTypes.func.isRequired,
  flush: PropTypes.bool.isRequired,
  isDirty: PropTypes.bool.isRequired,
  canAddRecord: PropTypes.bool.isRequired,
  filename: PropTypes.string,
  readOnly: PropTypes.bool.isRequired,
  disableExport: PropTypes.bool.isRequired,
};

// Users component
export default function CrudGrid(props) {
  // Destruct props
  const {
    fetchDataCb,
    onSaveCb,
    flush = false,
    flushCb = () => null,
    customColumns,
    getCustomActionsCb,
    validateRowCb,
    exportFilename,
    forceRefresh,
    disableExport = false,
    readOnly = false,
    canAddRecord = true,
  } = props;

  // Show dialog state
  const [showDialog, setShowDialog] = useState(false);

  // Get notification context
  const { setToast, setCanNavigate } = useAppState();

  // Current page state
  const [refresh, setRefresh] = useState(false);
  // Current page state
  const [page, setPage] = useState(0);
  // Page size state
  const [pageSize, setPageSize] = useState(100);
  // Row count state
  const [rowCount, setRowCount] = useState(0);

  // The server row data reference (used as a backup to undo changes)
  const serverRows = useRef([]);
  // The active row ID, with error data
  const activeRow = useRef({ id: null, hasError: false, message: "" });
  // Whether or not changes have been made to the grid data
  const [dirtyFlag, setDirtyFlag] = useState(false);
  // The row data displayed in the grid
  const [displayRows, setDisplayRows] = useState([]);
  // Rows that have been created
  const [createdRows, setCreatedRows] = useState([]);
  // Rows that have been edited
  const [editedRows, setEditedRows] = useState([]);
  // Rows that have been deleted
  const [deletedRows, setDeletedRows] = useState([]);

  // Page size state
  const [loading, setLoading] = useState(true);
  // Row mode state
  const [rowModesModel, setRowModesModel] = useState({});

  /**
   * Toolbar event handlers
   */

  // Handle an add record click on the toolbar
  const handleToolbarAddRecordClick = () => {
    // Create a random row ID
    const id = uuidv4();

    // Empty model template
    var model = { UUID: id, isNew: true };

    // Create default values based on columns
    for (const col of columns) {
      // Exit if the column data is invalid
      if (!col || !col.field || !col.default) continue;
      // Populate the model
      model[col.field] = col.default;
    }

    // Update displayed rows
    setDisplayRows((v) => [...v, { ...model }]);

    // Select the field to focus
    const focusField = columns && columns.length > 0 ? columns[0].field : "";

    // Set the row mode
    setRowModesModel((v) => ({
      ...v,
      [id]: {
        mode: GridRowModes.Edit,
        fieldToFocus: focusField,
      },
    }));

    // Set the active row
    activeRow.current = {
      id: id,
      hasError: true,
      message: "",
    };
  };

  // Handle a save changes click on the toolbar
  const handleToolbarSaveClick = async () => {
    setShowDialog(true);
  };

  // Close dialog handler
  const handleCloseDialog = () => {
    setShowDialog(false);
  };

  // Handle the confirm changes dialog's "confirm" event
  const handleConfirmChanges = async () => {
    try {
      // Call the on-save handler
      await onSaveCb({
        created: createdRows.map((r) => {
          // delete r.isNew;
          // delete r.UUID;
          return r;
        }),
        updated: editedRows.map((r) => {
          // delete r.isNew;
          return r;
        }),
        deleted: deletedRows.map((r) => {
          // delete r.isNew;
          return r;
        }),
      });

      // Close the dialog
      setShowDialog(false);

      // Trigger a data refresh
      setRefresh((v) => !v);
      // Show a toast
      setToast({
        message: `Changes saved: creating ${createdRows.length} / updating ${editedRows.length} / deleting ${deletedRows.length}`,
        type: "success", // success / error / warning / info / default
      });
    } catch (e) {
      // Show a toast
      setToast({
        message: e && e.message ? e.message : "Action failed",
        type: "error", // success / error / warning / info / default
      });
    }
  };

  // Handle a flush data click on the toolbar
  const handleToolbarFlushClick = async () => {
    try {
      // Call the flush handler
      await flushCb();
      // Show a toast
      setToast({
        message: `Data flushed successfully`,
        type: "success", // success / error / warning / info / default
      });
    } catch (e) {
      // Show a toast
      setToast({
        message: e && e.message ? e.message : "Action failed",
        type: "error", // success / error / warning / info / default
      });
    }
  };

  // Handle an undo changes click on the toolbar
  const handleToolbarUndoClick = () => {
    // Update the display rows state
    setDisplayRows(serverRows.current || []);

    // Clear secondary row data states
    setCreatedRows([]);
    setEditedRows([]);
    setDeletedRows([]);

    // Reset dirty flag
    setDirtyFlag(false);

    // Show a toast
    setToast({
      message: `Changes discarded`,
      type: "info", // success / error / warning / info / default
    });
  };

  /**
   * General grid event handlers
   */

  // Go the next/prev page
  const handlePageChange = (newPage) => {
    // Fire an alert if the page has pending changes
    if (dirtyFlag) {
      // Show a toast
      setToast({
        message:
          "Unsaved data will be lost - please undo or commit pending changes.",
        type: "error", // success / error / warning / info / default
      });
      return;
    }
    setPage(newPage);
  };

  // Change the number of rows shown on each page
  const handlePageSizeChange = (newPageSize) => {
    // Fire an alert if the page has pending changes
    if (dirtyFlag) {
      // Show a toast
      setToast({
        message:
          "Unsaved data will be lost - please undo or commit pending changes.",
        type: "error", // success / error / warning / info / default
      });
      return;
    }
    setPageSize(newPageSize);
  };

  /**
   * Row button event handlers
   */

  // Handle an edit button click
  const handleRowEditClick = (id) => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    activeRow.current = {
      data: displayRows.find((r) => r.UUID === id) || {},
      id: id,
      hasError: false,
      message: "",
    };
  };

  // Handle a delete row click
  const handleRowDeleteClick = (id) => {
    // Update the deleted rows if not newly created
    if (serverRows.current.find((r) => r.UUID === id)) {
      setDeletedRows((v) => {
        return [...v, ...displayRows.filter((r) => r.UUID === id)];
      });

      // Set dirty flag
      setDirtyFlag(true);

      // Show a toast
      setToast({
        message: `Row marked for deletion`,
        type: "info", // success / error / warning / info / default
      });
    }

    // Update the display rows
    setDisplayRows((v) => v.filter((r) => r.UUID !== id));
    // Update the created rows
    setCreatedRows((v) => v.filter((r) => r.UUID !== id));
    // Update the edited rows
    setEditedRows((v) => v.filter((r) => r.UUID !== id));
  };

  // Handle a row save click
  const handleRowSaveClick = (id) => {
    setRowModesModel((v) => ({ ...v, [id]: { mode: GridRowModes.View } }));
  };

  // Handle a row update
  const processRowUpdate = (newRow) => {
    // Create the new row
    const updatedRow = { ...newRow };
    // Find the current row
    const oldRow = displayRows.find((r) => r.UUID === updatedRow.UUID);

    // Validate the updated row data and fail gracefully
    if (!(validateRowCb instanceof Function) || !validateRowCb(updatedRow)) {
      // Show a toast
      setToast({
        message: "Update failed - some fields have invalid data.",
        type: "error", // success / error / warning / info / default
      });

      return updatedRow.isNew ? null : oldRow;
    }

    // Set dirty flag
    setDirtyFlag(true);
    // Reset the active row and its errors
    activeRow.current = { id: null, hasError: false, message: "" };

    // If the row is new, add it to the created rows array
    if (updatedRow.isNew) {
      setCreatedRows((v) => [...v, updatedRow]);
    }

    // Handle a subsequent edit of a newly created row
    if (
      !updatedRow.isNew &&
      createdRows.find((row) => row.UUID === updatedRow.UUID)
    ) {
      setCreatedRows((v) => {
        // Find an existing row with the same UUID
        var existing = v.find((row) => row.UUID === updatedRow.UUID);
        // Update the existing item
        v.splice(v[v.indexOf(existing)], 1, updatedRow);

        // Return the edited array
        return v;
      });
    }

    // Handle an edit of an existing row
    if (
      !updatedRow.isNew &&
      !createdRows.find((row) => row.UUID === updatedRow.UUID)
    ) {
      setEditedRows((v) => {
        // Find an existing row with the same UUID
        var existing = v.find((row) => row.UUID === updatedRow.UUID);

        // If not found, append it to the array
        if (!existing) return [...v, updatedRow];

        // Otherwise, update the existing record
        existing = updatedRow;

        // Return the edited array
        return v;
      });
    }

    // Reset the is new flag
    updatedRow.isNew = false;

    // Update the displayed rows
    setDisplayRows(
      displayRows.map((row) =>
        row.UUID === updatedRow.UUID ? updatedRow : row
      )
    );

    // Get the unedited row data
    const serverRow = serverRows.current.find(
      (r) => r.UUID === updatedRow.UUID
    );

    // Remove the row from the edited rows array if it matches its original state
    if (compareRows(serverRow, updatedRow)) {
      setEditedRows((v) => v.filter((r) => r.UUID !== updatedRow.UUID));
    }

    // Show a toast
    setToast({
      message: `Row marked for update`,
      type: "info", // success / error / warning / info / default
    });

    // Return the new row
    return updatedRow;
  };

  // Handle a cancel edit click
  const handleRowCancelClick = (id) => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    // Find the edited row
    const editedRow = displayRows.find((row) => row.UUID === id);
    // If the row has just been added, remove it from the displayed & created row arrays
    if (editedRow.isNew) {
      setDisplayRows((v) => v.filter((row) => row.UUID !== id));
      setCreatedRows((v) => v.filter((row) => row.UUID !== id));
    }

    // Reset the active row and its errors
    activeRow.current = { id: null, hasError: false, message: "" };
  };

  /**
   * Cell event handlers
   */

  // Handle a row's double click event
  const handleCellDoubleClick = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  // Handle a cell's keydown event
  const handleCellKeyDown = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  /**
   * State validation
   */

  // Compare two rows
  const compareRows = (r1, r2) => {
    // Exit if either object is invalid
    if (!r1 || !r2) return false;

    // Loop through and compare each property
    for (const key of Object.keys(r1)) {
      if (String(r1[key]) !== String(r2[key])) return false;
    }

    // The rows match one another
    return true;
  };

  // Check whether any row is currently active
  const isAnyRowActive = () => {
    return activeRow.current.id !== null;
  };

  // Confirm whether the row save button is disabled
  const isRowSaveDisabled = (id) => {
    // Disable the button if the given ID is not that of the active row
    if (activeRow.current.id !== id) return true;

    // Find the elements with the given data-id
    const elements = document.querySelectorAll(`[data-id="${id}"]`);
    const rowEl = elements && elements.length > 0 ? elements[0] : null;

    // Find editable cells within the row element
    if (!rowEl || !rowEl.children) return true;

    // An map of field names and their values
    var cellMap = {};

    for (const cell of rowEl.children) {
      // Skip if invalid
      if (!cell) continue;
      // Get the field name
      const field = cell.getAttribute("data-field");
      // Skip if the field name is invalid
      if (!field) continue;

      // Get all inputs in the cell
      const cellInputs = cell.getElementsByTagName("input"); // .getElementsByClassName("MuiInputBase-input");
      // Get the first input
      const primaryInput =
        cellInputs && cellInputs.length > 0 ? cellInputs[0] : null;

      // Skip if the input is invalid
      if (!primaryInput) continue;

      // Add to the array
      cellMap[field] = primaryInput.value;
    }

    // Loop through and validate fields
    for (const key of Object.keys(cellMap)) {
      activeRow.current.hasError = false;
      activeRow.current.message = "";

      // Skip if valid
      if (isCellValid(key, cellMap[key])) continue;

      // Set error state
      activeRow.current.hasError = true;
      activeRow.current.message = `Field [${key}] is invalid with value [${cellMap[key]}]`;

      // Break out of the loop
      break;
    }

    // If editing an existing row, check whether fields have changed
    if (activeRow.current.data) {
      for (const key of Object.keys(cellMap)) {
        if (String(cellMap[key]) !== String(activeRow.current.data[key])) {
          return false;
        }
      }
      // No changes, disable the button
      return true;
    }

    // Return the hasError flag, with a default of true to ensure the button is disabled initially
    return activeRow.current.hasError && true;
  };

  // Handle an edit start event
  const isCellValid = (field, value) => {
    // Find the relevant column definition
    const column = columns.find((v) => v.field === field);
    // Exit if invalid, or missing a validation function
    if (
      !column ||
      !column.editable ||
      !column.validate ||
      !(column.validate instanceof Function)
    )
      return true;

    // Call the validator with the current cell value
    return column.validate(value);
  };

  /**
   * Data helpers
   */

  // This function invokes the callback passed in by the parent component
  const fetchData = useCallback(async () => {
    try {
      // Show the loading indicator
      setLoading(true);

      // Request grid data
      const res = await fetchDataCb(pageSize, pageSize * page);

      // Update the server rows reference
      serverRows.current = res?.Data?.Records;
      // Update the display rows state
      setDisplayRows(res?.Data?.Records || []);
      // Update the row count
      setRowCount(res?.Data?.Count || 0);

      // Clear secondary row data states
      setCreatedRows([]);
      setEditedRows([]);
      setDeletedRows([]);

      // Reset dirty flag
      setDirtyFlag(false);

      // Reset the active row and its errors
      activeRow.current = { id: null, hasError: false, message: "" };

      // Hide the loading indicator
      setLoading(false);
    } catch (e) {
      // Show a toast
      setToast({
        message: e && e.message ? e.message : "Action failed",
        type: "error", // success / error / warning / info / default
      });
    }
  }, [page, pageSize, fetchDataCb, setToast]);

  // Run this effect when the page changes
  useEffect(() => {
    fetchData();
  }, [refresh, forceRefresh, fetchData]);

  // Run this effect when created/edited/deleted arrays change
  useEffect(() => {
    // Reset dirty flag if applicable
    if (
      createdRows.length === 0 &&
      editedRows.length === 0 &&
      deletedRows.length === 0
    )
      setDirtyFlag(false);
  }, [createdRows, editedRows, deletedRows]);

  // Run this effect when the dirty flag changes
  useEffect(() => {
    setCanNavigate(!dirtyFlag);
  }, [dirtyFlag, setCanNavigate]);

  // Add actions to the incoming column decriptions
  const columns = [
    ...customColumns,
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      flex: 1,
      minWidth: 150,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        // Return the edit-mode actions
        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={
                <Tooltip title="Save changes">
                  <SaveIcon />
                </Tooltip>
              }
              label="Save"
              onClick={() => handleRowSaveClick(id)}
              disabled={isRowSaveDisabled(id)}
            />,
            <GridActionsCellItem
              icon={
                <Tooltip title="Cancel action">
                  <CancelIcon />
                </Tooltip>
              }
              label="Cancel"
              className="textPrimary"
              onClick={() => handleRowCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        // Get custom actions
        const customActions =
          getCustomActionsCb && getCustomActionsCb instanceof Function
            ? getCustomActionsCb(
                displayRows.find((r) => r.UUID === id),
                id
              )
            : [];

        // An array of default actions
        var actions = [...customActions];

        // If not readonly, add deletion/edit actions
        if (!readOnly)
          actions.push(
            <GridActionsCellItem
              icon={
                <Tooltip title="Edit row">
                  <EditIcon />
                </Tooltip>
              }
              label="Edit"
              className="textPrimary"
              onClick={() => handleRowEditClick(id)}
              disabled={isAnyRowActive()}
              color="inherit"
            />,
            <GridActionsCellItem
              icon={
                <Tooltip title="Delete row">
                  <DeleteIcon />
                </Tooltip>
              }
              label="Delete"
              onClick={() => handleRowDeleteClick(id)}
              disabled={isAnyRowActive()}
              color="inherit"
            />
          );

        // Return the standard actions
        return actions;
      },
    },
  ];

  // Render the DOM
  return (
    <div className="crud-grid container">
      <ConfirmChangesDialog
        open={showDialog}
        created={createdRows}
        updated={editedRows}
        deleted={deletedRows}
        primaryText={
          customColumns && customColumns[0] && customColumns[0]["field"]
            ? customColumns[0]["field"]
            : ""
        }
        secondaryText={
          customColumns && customColumns[1] && customColumns[1]["field"]
            ? customColumns[1]["field"]
            : ""
        }
        onCloseCb={handleCloseDialog}
        onConfirmCb={handleConfirmChanges}
      />
      <DataGrid
        sx={{ overflowX: "auto" }}
        disableIgnoreModificationsIfProcessingProps
        rows={displayRows}
        columns={columns}
        rowCount={rowCount}
        loading={loading}
        paginationMode="server"
        pagination
        pageSize={pageSize}
        rowsPerPageOptions={[25, 50, 100]}
        onPageChange={handlePageChange}
        onPageSizeChange={handlePageSizeChange}
        initialState={{
          pagination: {
            page: 1,
            pageSize: 100,
          },
        }}
        getRowId={(row) => row?.UUID}
        getRowClassName={(params) => {
          // Style deleted rows
          if (params?.row?.Status && params?.row?.Status === "deleted")
            return "row-deleted";

          // Style error rows
          if (params?.row?.Tags?.search(/error/gi) >= 0) return "row-error";

          // // Style marked-for-deletion rows
          // if (deletedRows.find((r) => r.id === params?.row?.UUID))
          //   return "row-marked-for-deletion";

          // Default styling
          return "";
        }}
        // checkboxSelection
        disableSelectionOnClick
        editMode="cell"
        rowModesModel={rowModesModel}
        onRowModesModelChange={(newModel) => setRowModesModel(newModel)}
        processRowUpdate={processRowUpdate}
        onProcessRowUpdateError={(error) =>
          // Show a toast
          setToast({
            message: `Update error: ${error.message || ""}`,
            type: "error", // success / error / warning / info / default
          })
        }
        onCellDoubleClick={handleCellDoubleClick}
        onCellKeyDown={handleCellKeyDown}
        components={{
          Toolbar: EditToolbar,
        }}
        componentsProps={{
          toolbar: {
            onAddRecordClickCb: handleToolbarAddRecordClick,
            onSaveChangesClickCb: handleToolbarSaveClick,
            onUndoChangesClickCb: handleToolbarUndoClick,
            onFlushDataClickCb: handleToolbarFlushClick,
            flush: flush,
            isDirty: dirtyFlag,
            canAddRecord: !isAnyRowActive() && canAddRecord,
            filename: exportFilename,
            readOnly: readOnly,
            disableExport: disableExport,
          },
        }}
        experimentalFeatures={{ newEditingApi: true }}
      />
    </div>
  );
}

// import Box from "@mui/material/Box";
// import { styled } from "@mui/material/styles";
// // Theme used for the empty grid overlay
// const StyledGridOverlay = styled("div")(({ theme }) => ({
//   display: "flex",
//   flexDirection: "column",
//   alignItems: "center",
//   justifyContent: "center",
//   height: "100%",
//   "& .ant-empty-img-1": {
//     fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
//   },
//   "& .ant-empty-img-2": {
//     fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
//   },
//   "& .ant-empty-img-3": {
//     fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
//   },
//   "& .ant-empty-img-4": {
//     fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
//   },
//   "& .ant-empty-img-5": {
//     fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
//     fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
//   },
// }));
// // A styled overlay for an empty grid
// function CustomNoRowsOverlay() {
//   return (
//     <StyledGridOverlay>
//       <svg
//         width="120"
//         height="100"
//         viewBox="0 0 184 152"
//         aria-hidden
//         focusable="false"
//       >
//         <g fill="none" fillRule="evenodd">
//           <g transform="translate(24 31.67)">
//             <ellipse
//               className="ant-empty-img-5"
//               cx="67.797"
//               cy="106.89"
//               rx="67.797"
//               ry="12.668"
//             />
//             <path
//               className="ant-empty-img-1"
//               d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
//             />
//             <path
//               className="ant-empty-img-2"
//               d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
//             />
//             <path
//               className="ant-empty-img-3"
//               d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
//             />
//           </g>
//           <path
//             className="ant-empty-img-3"
//             d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
//           />
//           <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
//             <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
//             <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
//           </g>
//         </g>
//       </svg>
//       <Box sx={{ mt: 1 }}>No Rows</Box>
//     </StyledGridOverlay>
//   );
// }
