import { isValidEmail } from "../../common/validationHelpers";
import CrudGrid from "../CrudGrid/CrudGrid";
import BlockIcon from "@mui/icons-material/Block";
import LockResetIcon from "@mui/icons-material/LockReset";
import { GridActionsCellItem } from "@mui/x-data-grid";
import Tooltip from "@mui/material/Tooltip";
import { useApi } from "../ApiProvider/ApiProvider";
import { useState } from "react";
import { useAppState } from "../AppStateProvider/AppStateProvider";

// Users component
export default function Users() {
  // Get user details
  const { userApi, appState } = useApi();

  // This state refreshes the view
  const [refresh, setRefresh] = useState(false);

  // Get notification context
  const { setToast } = useAppState();

  // Handle a revoke request
  const onRevoke = async (id) => {
    try {
      await userApi.revokeToken({ uuid: id });
      setRefresh((v) => !v);
    } catch (e) {
      // Show an error message
      setToast({
        message: e && e.message ? e.message : "Action failed",
        type: "error", // success / error / warning / info / default
      });
    }
  };

  // Handle a profile reset request
  const onResetProfile = async (id) => {
    try {
      await userApi.resetProfile({ uuid: id });
      setRefresh((v) => !v);
    } catch (e) {
      // Show an error message
      setToast({
        message: e && e.message ? e.message : "Action failed",
        type: "error", // success / error / warning / info / default
      });
    }
  };

  // Validate any created or edited user model
  const validateModel = (model) => {
    // Create the validity flag
    var isValid = true;

    // Validate name
    if (!model.Name || model.Name.length < 1) isValid = false;

    // Validate surname
    if (!model.Surname || model.Surname.length < 1) isValid = false;

    // Validate email
    if (!model.Email || !isValidEmail(model.Email)) isValid = false;

    // Validate role
    if (!model.Role || !appState.roles.includes(model.Role)) isValid = false;

    // Return the result
    return isValid;
  };

  // Define custom actions for the users grid
  const getCustomActions = (data, id) => {
    var options = [
      <GridActionsCellItem
        icon={
          <Tooltip title="Force profile reset">
            <LockResetIcon />
          </Tooltip>
        }
        label="Reset"
        onClick={() => onResetProfile(id)}
        color="inherit"
      />,
    ];

    // Only deleted items can be reinstated
    if (data?.Status !== "deleted")
      options.push(
        <GridActionsCellItem
          icon={
            <Tooltip title="Revoke access tokens">
              <BlockIcon />
            </Tooltip>
          }
          label="Revoke"
          // className="textPrimary"
          onClick={() => onRevoke(id)}
          color="inherit"
        />
      );

    return options;
  };

  // Define the columns for the users grid
  const columns = [
    {
      field: "Name",
      headerName: "Name",
      flex: 2,
      minWidth: 100,
      editable: true,
      preProcessEditCellProps: (params) => {
        const hasError = !params.props.value || params.props.value.length < 1;
        return { ...params.props, error: hasError };
      },
      validate: (v) => {
        return !!(v && v.length > 0);
      },
    },
    {
      field: "Surname",
      headerName: "Surname",
      flex: 2,
      minWidth: 100,
      editable: true,
      preProcessEditCellProps: (params) => {
        const hasError = !params.props.value || params.props.value.length < 1;
        return { ...params.props, error: hasError };
      },
      validate: (v) => {
        return !!(v && v.length > 0);
      },
    },
    {
      field: "Email",
      headerName: "Email",
      flex: 2,
      minWidth: 150,
      editable: true,
      preProcessEditCellProps: (params) => {
        const hasError = !isValidEmail(params.props.value);
        return { ...params.props, error: hasError };
      },
      validate: (v) => {
        return isValidEmail(v);
      },
    },
    {
      field: "Status",
      headerName: "Status",
      flex: 2,
      minWidth: 100,
      editable: false,
    },
    {
      field: "Role",
      headerName: "Role",
      flex: 2,
      minWidth: 100,
      editable: true,
      type: "singleSelect",
      default: appState.roles && appState.roles[0] ? appState.roles[0] : "",
      valueOptions: appState.roles,
      preProcessEditCellProps: (params) => {
        const hasError =
          !params.props.value || !appState.roles.includes(params.props.value);
        return { ...params.props, error: hasError };
      },
      validate: (v) => {
        return appState.roles.includes(v);
      },
    },
  ];

  // Render the DOM
  return (
    <div className="users container">
      <CrudGrid
        customColumns={columns}
        getCustomActionsCb={getCustomActions}
        fetchDataCb={userApi.get}
        onSaveCb={userApi.update}
        validateRowCb={validateModel}
        exportFilename={"users"}
        forceRefresh={refresh}
      />
    </div>
  );
}
