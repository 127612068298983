import { createContext, useContext, useMemo, useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import { useRef } from "react";

// Create an notification context for all routes
const AppStateContext = createContext();

// Auth provider helper function
export function AppStateProvider({ children }) {
  // Alert packet state
  const [toast, setToast] = useState({
    message: "",
    type: "", // success / error / warning / info / default
    duration: 0,
  });

  // This state dictates whether navigation is enabled
  const [canNavigate, setCanNavigate] = useState(true);

  // The current snackbar key
  const snackbarKey = useRef();

  // Get the snackbar context
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  // Run this effect each time an alert is triggered
  useEffect(() => {
    // Exit if the alert is invalid
    if (!toast || !toast.message || toast.message.length < 1) return;

    // Set the alert anchor point
    var toastAnchor = {
      vertical: "bottom",
      horizontal: "left",
    };

    // // Change origin for errors
    // if (alert.type === "error") {
    //   alertAnchor.vertical = "top";
    //   alertAnchor.horizontal = "center";
    // }

    // // Change origin for success cases
    // if (alert.type === "success") {
    //   alertAnchor.vertical = "bottom";
    //   alertAnchor.horizontal = "center";
    // }

    // Ensure the current snackbar is closed
    closeSnackbar(snackbarKey.current);

    // Only enqueue if valid
    snackbarKey.current = enqueueSnackbar(toast.message, {
      anchorOrigin: toastAnchor,
      variant: toast.type,
      autoHideDuration: toast.duration || 5000,
    });
  }, [toast, enqueueSnackbar, closeSnackbar]);

  // User data and methods
  const value = useMemo(
    () => ({
      toast,
      setToast,
      canNavigate,
      setCanNavigate,
    }),
    [toast, canNavigate]
  );

  return (
    <AppStateContext.Provider value={value}>
      {children}
    </AppStateContext.Provider>
  );
}

// Custom hook to provide access to notification details
export function useAppState() {
  return useContext(AppStateContext);
}

// eslint-disable-next-line
