import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { CircularProgress } from "@mui/material";
import { Avatar, Button, Stack, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { isValidEmail, isValidPassword } from "../../common/validationHelpers";
import { useApi } from "../ApiProvider/ApiProvider";
import { useAppState } from "../AppStateProvider/AppStateProvider";

// Login form helper component
export const LoginForm = () => {
  // Get auth context
  const { authApi, setSessionState } = useApi();

  // Get notification context
  const { setToast } = useAppState();

  // Get notification context
  const navigate = useNavigate();

  // Loading state
  const [loading, setLoading] = useState(false);

  // Form field touched state
  const [touched, setTouched] = useState({ email: false, password: false });

  // Email field state
  const [email, setEmail] = useState("");
  // Password field state
  const [password, setPassword] = useState("");

  // On-change handler
  const handleChange = (event, stateSetter) => {
    stateSetter(event.target.value);
  };

  // Handle the form submission
  const onSubmit = async () => {
    try {
      // Show the loading indicator
      setLoading(true);

      // Submit the login request
      const res = await authApi.login({ email: email, password: password });

      // Update the user object
      setSessionState(res?.Data);

      // Show a toast
      setToast({
        message: "Login successful",
        type: "success", // success / error / warning / info / default
      });
    } catch (e) {
      // Show a toast
      setToast({
        message: e && e.message ? e.message : "Action failed",
        type: "error", // success / error / warning / info / default
      });
    }

    // Hide the loading indicator
    setLoading(false);
  };

  // Form validation helper
  const isFormInvalid = () => {
    return isValidEmail(email) && isValidPassword(password);
  };

  return (
    <>
      <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
        <LockOutlinedIcon />
      </Avatar>
      <Typography component="h5" variant="h5">
        Log in
      </Typography>
      <Stack
        component="form"
        sx={{
          marginTop: 1,
          width: "30ch",
        }}
        spacing={2}
        autoComplete="off"
      >
        <TextField
          onBlur={() => {
            setTouched((v) => ({ ...v, email: true }));
          }}
          error={touched.email && !isValidEmail(email)}
          helperText="Please enter a valid email address"
          id="login-email"
          label="Email"
          variant="outlined"
          margin="normal"
          type={"email"}
          required
          onChange={(e) => handleChange(e, setEmail)}
        />

        <TextField
          onBlur={() => {
            setTouched((v) => ({ ...v, password: true }));
          }}
          error={touched.password && !isValidPassword(password)}
          helperText={<PasswordHelperText />}
          id="login-password"
          label="Password"
          variant="outlined"
          margin="normal"
          type={"password"}
          required
          onChange={(e) => handleChange(e, setPassword)}
        />

        <Button
          variant="outlined"
          size="large"
          onClick={() => onSubmit()}
          disabled={loading || !isFormInvalid()}
        >
          {loading ? (
            <span>
              <CircularProgress size={15} />
            </span>
          ) : (
            <span>Login</span>
          )}
        </Button>

        <Stack
          direction="row"
          justifyContent="space-evenly"
          alignItems="center"
          spacing={2}
        >
          <Button
            variant="text"
            size="small"
            onClick={() => navigate("/auth/register")}
          >
            Sign up
          </Button>
          <Button
            variant="text"
            size="small"
            onClick={() => navigate("/auth/resetrequest")}
          >
            Reset password
          </Button>
        </Stack>
      </Stack>
    </>
  );
};

// A helper component for password hints
export const PasswordHelperText = () => {
  return (
    <>
      <span>&bull; Minimum of 8 characters</span>
      <br />
      <span>&bull; At least one uppercase letter</span>
      <br />
      <span>&bull; At least one lowercase letter</span>
      <br />
      <span>&bull; At least one digit</span>
      <br />
      <span>&bull; At least one special character</span>
      <br />
    </>
  );
};
