import "./AppLayout.css";
import { Outlet, useLocation } from "react-router-dom";
import Sidebar from "../Navbar/SideBar";
import { motion } from "framer-motion";
import { useApi } from "../ApiProvider/ApiProvider";

// A helper component for standard app layout
export function AppLayout() {
  // Get api
  const { appState } = useApi();

  // App location info
  const { pathname } = useLocation();

  // Animation variants
  const animVariants = {
    initial: {
      opacity: 0,
    },
    in: {
      opacity: 1,
    },
    out: {
      opacity: 0,
    },
  };

  // Transition definition
  const animTransition = {
    type: "tween",
    ease: "linear",
    duration: 0.5,
  };

  // Render the DOM
  return (
    <div className="container flex-row" style={{ overflow: "hidden" }}>
      {appState.user && (
        <div style={{ flex: 15 }}>
          <Sidebar />
        </div>
      )}
      <div style={{ flex: 85 }}>
        <motion.div
          key={pathname}
          initial="initial"
          animate="in"
          variants={animVariants}
          transition={animTransition}
          className="container"
        >
          <Outlet />
        </motion.div>
      </div>
    </div>
  );
}
