import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { Typography } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import Tooltip from "@mui/material/Tooltip";
import { copyToClipboard, exportBlob } from "../../common/utility";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useAppState } from "../AppStateProvider/AppStateProvider";

export default function ApiKeyDialog(props) {
  // Destruct props
  const { open, tokens, onCloseCb } = props;
  // Get notification context
  const { setToast } = useAppState();

  // Get theme context
  const theme = useTheme();
  // Calculate screen breakpoints
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  // Dialog close handler
  const onClose = (event, reason) => {
    // Do not close for background clicks
    if (reason === "backdropClick") return;
    // Invoke the given callback
    onCloseCb();
  };

  // This handler copies the key to the clipboard
  const onCopyToClipboard = (token) => {
    copyToClipboard(token.Key);
    // Notify the user
    setToast({
      message: "Copied to clipboard",
      type: "success", // success / error / warning / info / default
    });
  };

  // This handler exports the newly created key to a text file
  const onDownload = (token) => {
    const blob = new Blob([token.Key], {
      type: "text/plain",
    });
    exportBlob(
      blob,
      `${(token.FriendlyName || "").replace(/ +/gi, "_")}.cloud-auth`
    );
    // Notify the user
    setToast({
      message: "Download complete",
      type: "success", // success / error / warning / info / default
    });
  };

  // This handler exports all newly created keys to their own text files
  const onDownloadAll = (tokens) => {
    tokens.forEach((token) => {
      const blob = new Blob([token.Key], {
        type: "text/plain",
      });
      exportBlob(
        blob,
        `${(token.FriendlyName || "").replace(/ +/gi, "_")}.cloud-auth`
      );
    });
    // Notify the user
    setToast({
      message: "Download complete",
      type: "success", // success / error / warning / info / default
    });
  };

  return (
    <div className="token-dialog">
      <Dialog
        open={open}
        onClose={onClose}
        fullScreen={fullScreen}
        sx={{ "& .MuiDialog-paper": { width: "100%", maxHeight: 750 } }}
        disableEscapeKeyDown
      >
        <DialogTitle>
          New Token(s): {tokens?.length ? `${tokens.length} ` : ""}
          <Button
            variant="outlined"
            style={{ float: "right" }}
            onClick={() => onDownloadAll(tokens)}
          >
            <Tooltip title="Download All">
              <Typography variant="body1">Download All</Typography>
            </Tooltip>
          </Button>
          <Typography variant="body1" textAlign={"left"}>
            <strong className="uppercase">
              This screen will only be displayed once.
            </strong>
          </Typography>
        </DialogTitle>
        <DialogContent dividers>
          {tokens && tokens.length > 0 && (
            <Grid item xs={12} md={6}>
              <List dense={true}>
                {(tokens || []).map((token, index) => (
                  <ListItem key={token.FriendlyName + "_li_" + index}>
                    <ListItemText
                      primary={token.FriendlyName}
                      secondary={token.Attributes}
                    />
                    <Button
                      variant="outlined"
                      onClick={() => onCopyToClipboard(token)}
                    >
                      <Tooltip title="Copy">
                        <ContentCopyIcon />
                      </Tooltip>
                    </Button>
                    <Button
                      variant="outlined"
                      onClick={() => onDownload(token)}
                    >
                      <Tooltip title="Download">
                        <DownloadIcon />
                      </Tooltip>
                    </Button>
                    <Divider />
                  </ListItem>
                ))}
              </List>
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
