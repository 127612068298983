import { useCallback, useState } from "react";
import { useApi } from "../ApiProvider/ApiProvider";
import { useAppState } from "../AppStateProvider/AppStateProvider";
import { useEffect } from "react";
import { Loading } from "../Loading/Loading";
import moment from "moment";

import {
  PieChart,
  Pie,
  Cell,
  Legend,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { Divider, Typography } from "@mui/material";

// Pie chart segment colours
const pieSegmentColours = [
  "#0088FE",
  "#00C49F",
  "#FFBB28",
  "#FF8042",
  "AA77FF",
];
// // Rad helper
// const RADIAN = Math.PI / 180;
// // Custom pie chart element label helper
// const customPieLabel = ({
//   cx,
//   cy,
//   midAngle,
//   innerRadius,
//   outerRadius,
//   percent,
//   index,
// }) => {
//   const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
//   const x = cx + radius * Math.cos(-midAngle * RADIAN);
//   const y = cy + radius * Math.sin(-midAngle * RADIAN);

//   return (
//     <text
//       x={x}
//       y={y}
//       fill="white"
//       textAnchor={x > cx ? "start" : "end"}
//       dominantBaseline="central"
//     >
//       {`${(percent * 100).toFixed(0)}%`}
//     </text>
//   );
// };

// This component manages reading and parsing data from the bloomberg indices cache
export function ApiUsageDashboard() {
  // Get the feeds api
  const { tokenApi } = useApi();

  // Get notification context
  const { setToast } = useAppState();

  // Activity categories (today / this week / this month / more than a month / never)
  // Activity pie chart data
  const [activityChartData, setActivityChartData] = useState([]);

  // Total number of keys
  const [, setTotalKeys] = useState(0);

  // Loading state
  const [loading, setLoading] = useState(false);

  // x % of keys were active in the last day / week / month
  // of those that were active in each frame - here are the common permissions
  // of those that were not active - here are the common permissions

  // Fetch data from the server
  const fetch = useCallback(async () => {
    try {
      // Show the loading indicator
      setLoading(true);
      // Get token usage data
      const res = await tokenApi.usage();

      // Exit if invalid
      if (!res || !res.Data || !res.Data.Count || !res.Data.Records) {
        throw new Error("invalid or empty usage data");
      }

      // Set the total number of keys retrieved
      setTotalKeys(res.Data.Count);

      // Filter the keys into their respective activity categories
      var records = res.Data.Records;

      // Never used
      const unused = (records || []).filter(
        (r) => Math.abs(moment(r.LastSeen).year()) === 1
      );

      // Remove 'unused' entries from pool
      records = records.filter((r) => !unused.includes(r));

      // Active today
      const today = (records || []).filter((r) => {
        const lastActive = moment(r.LastSeen);
        return Math.abs(moment().diff(lastActive, "days")) < 1;
      });

      // Remove 'today' entries from pool
      records = records.filter((r) => !today.includes(r));

      // Active this week
      const week = (records || []).filter((r) => {
        const lastActive = moment(r.LastSeen);
        return Math.abs(moment().diff(lastActive, "weeks")) < 1;
      });

      // Remove 'week' entries from pool
      records = records.filter((r) => !week.includes(r));

      // Active this month
      const month = (records || []).filter((r) => {
        const lastActive = moment(r.LastSeen);
        return Math.abs(moment().diff(lastActive, "months")) < 1;
      });

      // Inactive for over a month (stale)
      const stale = records.filter((r) => !month.includes(r));

      // console.log(today, week, month, stale, unused);

      // Update the chart data
      setActivityChartData((v) => {
        // Populate the list based on data received
        var data = [];

        // Add "Today" data
        if ((today || []).length > 0) {
          data.push({ name: "Today", value: (today || []).length || 0 });
        }

        // Add "This week" data
        if ((week || []).length > 0) {
          data.push({
            name: "This week",
            value: (week || []).length || 0,
          });
        }

        // Add "This month" data
        if ((month || []).length > 0) {
          data.push({
            name: "This month",
            value: (month || []).length || 0,
          });
        }

        // Add "More than a month" data
        if ((stale || []).length > 0) {
          data.push({
            name: "More than a month",
            value: (stale || []).length || 0,
          });
        }

        // Add "Never" data
        if ((unused || []).length > 0) {
          data.push({
            name: "Never",
            value: (unused || []).length || 0,
          });
        }

        return data;
      });
    } catch (e) {
      // Show a toast
      setToast({
        message: e && e.message ? e.message : "Action failed",
        type: "error", // success / error / warning / info / default
      });
    }

    // Hide the loading indicator
    setLoading(false);
  }, [setToast, tokenApi]);

  // Run this effect when the refresh flag changes
  useEffect(() => {
    fetch();
  }, [fetch]);

  // Get the elements to render
  const getElements = () => {
    // Show the loading component if applicable
    if (loading) {
      return <Loading />;
    }

    // Render the DOM
    return (
      <div className="container scroll-y scroll-x">
        <Typography component="h5" variant="h5" gutterBottom>
          {activityChartData?.length ? (
            <></>
          ) : (
            <span className="capitalise-first">No data</span>
          )}
          <Divider orientation="horizontal" />
        </Typography>
        <ResponsiveContainer height="50%">
          <PieChart width={500} height={500}>
            <Pie
              dataKey="value"
              isAnimationActive={true}
              data={activityChartData}
              cx="50%"
              cy="50%"
              // innerRadius={40}
              outerRadius={80}
              fill="#8884d8"
              labelLine={false}
              // label={customPieLabel}
              label
            >
              {(activityChartData || []).map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={pieSegmentColours[index % pieSegmentColours.length]}
                />
              ))}
            </Pie>
            <Legend />
            <Tooltip />
          </PieChart>
        </ResponsiveContainer>
      </div>
    );
  };

  // Render the DOM
  return getElements();
}
