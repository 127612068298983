import { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { CircularProgress } from "@mui/material";
import { useAppState } from "../AppStateProvider/AppStateProvider";
import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import { prettyPrint } from "../../common/utility";

// Helper component to force confirmation of changes to grid data
export default function ConfirmChangesDialog(props) {
  // Destruct props
  const {
    open,
    created,
    updated,
    deleted,
    primaryText,
    secondaryText,
    onConfirmCb,
    onCloseCb,
  } = props;

  // Get theme context
  const theme = useTheme();
  // Calculate screen breakpoints
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  // Loading state
  const [loading, setLoading] = useState(false);

  // Get notification context
  const { setToast } = useAppState();

  // Dialog close handler
  const onClose = (event, reason) => {
    // Do not close for background clicks
    if (reason === "backdropClick") return;
    // Invoke the given callback
    onCloseCb();
  };

  // Form submission handler
  const onConfirm = async () => {
    try {
      // Show the loading indicator
      setLoading(true);
      // Wait for the confirmation callback to complete
      await onConfirmCb();
    } catch (e) {
      // Show a toast
      setToast({
        message: e && e.message ? e.message : "Action failed",
        type: "error", // success / error / warning / info / default
      });
    }

    // Hide the loading indicator
    setLoading(false);
  };

  return (
    <div className="token-dialog">
      <Dialog
        open={open}
        onClose={onClose}
        fullScreen={fullScreen}
        sx={{ "& .MuiDialog-paper": { width: "100%", maxHeight: 750 } }}
        disableEscapeKeyDown
      >
        <DialogTitle>
          Confirm changes to (
          {(created || []).length +
            (updated || []).length +
            (deleted || []).length}
          ) rows
        </DialogTitle>
        <DialogContent dividers>
          <Grid item xs={12} md={6}>
            <Typography sx={{ mt: 4, mb: 2 }} variant="body1">
              Created rows
              <Typography
                sx={{ ml: 1 }}
                variant="body1"
                component="span"
                color={"lightgreen"}
              >
                ({(created || []).length})
              </Typography>
            </Typography>
            <Divider />
            <List dense={true}>
              {(created || []).map((obj, index) => (
                <ListItem key={"created_" + index}>
                  <ListItemText
                    primary={
                      obj && obj[primaryText]
                        ? `${prettyPrint(primaryText)}: ${obj[primaryText]}`
                        : "Invalid"
                    }
                    secondary={
                      obj && obj[secondaryText]
                        ? `${prettyPrint(secondaryText)}: ${obj[secondaryText]}`
                        : "Invalid"
                    }
                  />
                  <Divider />
                </ListItem>
              ))}
              {(!created || !created.length) && (
                <ListItem key={"created_none"}>
                  <ListItemText primary={"None"} />
                  <Divider />
                </ListItem>
              )}
            </List>

            <Typography sx={{ mt: 4, mb: 2 }} variant="body1">
              Updated rows
              <Typography
                sx={{ ml: 1 }}
                variant="body1"
                component="span"
                color={"orange"}
              >
                ({(updated || []).length})
              </Typography>
            </Typography>
            <Divider />
            <List dense={true}>
              {(updated || []).map((obj, index) => (
                <ListItem key={"updated_" + index}>
                  <ListItemText
                    primary={
                      obj && obj[primaryText]
                        ? `${prettyPrint(primaryText)}: ${obj[primaryText]}`
                        : "Invalid"
                    }
                    secondary={
                      obj && obj[secondaryText]
                        ? `${prettyPrint(secondaryText)}: ${obj[secondaryText]}`
                        : "Invalid"
                    }
                  />
                  <Divider />
                </ListItem>
              ))}
              {(!updated || !updated.length) && (
                <ListItem key={"updated_none"}>
                  <ListItemText primary={"None"} />
                  <Divider />
                </ListItem>
              )}
            </List>

            <Typography sx={{ mt: 4, mb: 2 }} variant="body1">
              Deleted rows
              <Typography
                sx={{ ml: 1 }}
                variant="body1"
                component="span"
                color={"error"}
              >
                ({(deleted || []).length})
              </Typography>
            </Typography>
            <Divider />
            <List dense={true}>
              {(deleted || []).map((obj, index) => (
                <ListItem key={"deleted_" + index}>
                  <ListItemText
                    primary={
                      obj && obj[primaryText]
                        ? `${prettyPrint(primaryText)}: ${obj[primaryText]}`
                        : "Invalid"
                    }
                    secondary={
                      obj && obj[secondaryText]
                        ? `${prettyPrint(secondaryText)}: ${obj[secondaryText]}`
                        : "Invalid"
                    }
                  />
                  <Divider />
                </ListItem>
              ))}
              {(!deleted || !deleted.length) && (
                <ListItem key={"deleted_none"}>
                  <ListItemText primary={"None"} />
                  <Divider />
                </ListItem>
              )}
            </List>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} disabled={loading}>
            Close
          </Button>
          <Button
            variant="outlined"
            size="large"
            onClick={onConfirm}
            disabled={loading}
          >
            {loading ? (
              <span>
                <CircularProgress size={15} />
              </span>
            ) : (
              <span>Confirm</span>
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
