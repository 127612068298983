import { useCallback, useState } from "react";
import { useApi } from "../ApiProvider/ApiProvider";
import { useAppState } from "../AppStateProvider/AppStateProvider";
import { useEffect } from "react";
import IconButton from "@mui/material/IconButton";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DownloadIcon from "@mui/icons-material/Download";
import { copyToClipboard, exportBlob } from "../../common/utility";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useTheme } from "@mui/material/styles";
import moment from "moment";
import CrudGrid from "../CrudGrid/CrudGrid";
import { GridActionsCellItem } from "@mui/x-data-grid";
import {
  CircularProgress,
  Divider,
  Grid,
  Tooltip,
  Typography,
} from "@mui/material";

// This component manages reading and parsing data from the bloomberg indices cache
export function BloombergIndices() {
  // Get the feeds api
  const { feedsApi } = useApi();

  return (
    <BlobGrid
      filesuffix={"_indices.json"}
      fetchCb={feedsApi.bloombergIndices}
      flush
      flushCb={feedsApi.flushBloombergIndices}
    />
  );
}

// This component manages reading and parsing data from the bloomberg news cache
export function BloombergNews() {
  // Get the feeds api
  const { feedsApi } = useApi();

  return (
    <BlobGrid filesuffix={"_news.json"} fetchCb={feedsApi.bloombergNews} />
  );
}

// This component manages reading and parsing data from the bloomberg news cache
export function InvestecFocusFeeds() {
  // Get the feeds api
  const { feedsApi } = useApi();

  return (
    <BlobGrid
      filesuffix={"_focusfeeds.json"}
      fetchCb={feedsApi.investecFocusFeeds}
    />
  );
}

// Generic blob cache grid with object viewer dialog
export function BlobGrid(props) {
  // Destruct props
  const { filesuffix, fetchCb, flush, flushCb } = props;

  // Show dialog state
  const [showDialog, setShowDialog] = useState(false);
  // Dialog title and value to display
  const [dialogTitle, setDialogTitle] = useState("");
  // Selected blob object ID
  const [objectId, setObjectId] = useState("");

  // Close dialog handler
  const handleCloseDialog = () => {
    setShowDialog(false);
  };

  // This function handles a "view" button click
  const handleViewClick = (data) => {
    // Set dialog states
    setDialogTitle(moment(data.Name).local().format("HH:mm:ss YYYY-MM-DD"));
    setObjectId(data.ObjectID);
    // Show the dialog
    setShowDialog(true);
  };

  // Define custom actions for the devices grid
  const getCustomActions = (data, id) => {
    var options = [];

    options.push(
      <GridActionsCellItem
        icon={
          <Tooltip title="View data">
            <VisibilityIcon />
          </Tooltip>
        }
        label="View"
        onClick={() => handleViewClick(data)}
        color="inherit"
      />
    );

    return options;
  };

  // Define the columns for the users grid
  const columns = [
    {
      field: "Name",
      headerName: "Name",
      flex: 2,
      minWidth: 100,
      valueGetter: (params) => {
        if (!params.value) {
          return params.value;
        }
        // Convert the decimal value to a percentage
        return moment(params.value).local().format("HH:mm:ss YYYY-MM-DD");
      },
    },
    {
      field: "Tags",
      headerName: "Tags",
      flex: 2,
      minWidth: 100,
    },
  ];

  // Render the DOM
  return (
    <div className="blob-cache container">
      <CrudGrid
        customColumns={columns}
        getCustomActionsCb={getCustomActions}
        fetchDataCb={fetchCb}
        flushCb={flushCb}
        flush={flush}
        exportFilename={filesuffix}
        disableExport
        readOnly
      />
      <ShowObjectDialog
        open={showDialog}
        onCloseCb={handleCloseDialog}
        title={dialogTitle}
        id={objectId}
        filesuffix={filesuffix}
      />
    </div>
  );
}

// Helper component to display an object
function ShowObjectDialog(props) {
  // Destruct props
  const { open, onCloseCb, title, id, filesuffix } = props;

  // Get notification context
  const { setToast } = useAppState();

  // Storage API
  const { storageApi } = useApi();

  // Get theme context
  const theme = useTheme();
  // Calculate screen breakpoints
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  // Loading state
  const [loading, setLoading] = useState(false);

  // The payload to display
  const [payload, setPayload] = useState(null);

  // This function invokes the callback passed in by the parent component
  const fetch = useCallback(async () => {
    try {
      // Show the loading indicator
      setLoading(true);

      // Request the storage object
      const res = await storageApi.get(id);

      // Set the payload
      setPayload(res?.Data);

      // Hide the loading indicator
      setLoading(false);
    } catch (e) {
      // Show a toast
      setToast({
        message: e && e.message ? e.message : "Action failed",
        type: "error", // success / error / warning / info / default
      });
    }
  }, [id, storageApi, setToast]);

  // Run this effect when the dialog opens/closes
  useEffect(() => {
    if (open) fetch();
  }, [open, fetch]);

  // Dialog close handler
  const onClose = (event, reason) => {
    // Do not close for background clicks
    if (reason === "backdropClick") return;
    // Invoke the given callback
    onCloseCb();
  };

  // This handler exports an object to a json file
  const handleDownloadClick = (val, name) => {
    // Blob type
    var blobType = "text/plain";

    // Change the blob type based on the given prefix
    if (`${filesuffix}`.search(/json/gi) >= 0) {
      blobType = "text/json";
    }

    // Create and export the blob
    const blob = new Blob([val], {
      type: blobType,
    });
    exportBlob(blob, `${(name || "").replace(/ +/gi, "_")}${filesuffix}`);
  };

  // Copy the object to user clipboard
  const handleCopyClick = (payload) => {
    setToast({
      message: "Copied to clipboard",
      type: "info", // success / error / warning / info / default
    });
    copyToClipboard(payload);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen={fullScreen}
      sx={{ "& .MuiDialog-paper": { width: "100%", maxHeight: 750 } }}
      disableEscapeKeyDown
    >
      <DialogTitle>
        {title}

        <IconButton
          edge="end"
          aria-label="copy"
          sx={{ marginRight: "5px" }}
          disabled={loading}
          onClick={() => handleCopyClick(JSON.stringify(payload, null, 2))}
        >
          <ContentCopyIcon />
        </IconButton>
        <IconButton
          edge="end"
          aria-label="download"
          disabled={loading}
          onClick={() =>
            handleDownloadClick(JSON.stringify(payload, null, 2), title)
          }
        >
          <DownloadIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Grid item xs={12} md={6}>
          {loading ? (
            <span>
              <CircularProgress size={32} />
            </span>
          ) : (
            <>
              <Typography sx={{ mt: 4, mb: 2 }} variant="body1">
                Errors
              </Typography>
              <Divider />
              <pre style={{ textAlign: "left" }}>
                {payload?.Errors
                  ? JSON.stringify(payload?.Errors, null, 2)
                  : "N/A"}
              </pre>

              <Typography sx={{ mt: 4, mb: 2 }} variant="body1">
                Data
              </Typography>
              <Divider />
              <pre style={{ textAlign: "left" }}>
                {JSON.stringify(payload, null, 2)}
              </pre>
            </>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}
