// Helper function to export data blobs to file
export const exportBlob = (blob, filename) => {
  // Save the blob in a json file
  const url = URL.createObjectURL(blob);

  const a = document.createElement("a");
  a.href = url;
  a.download = filename;
  a.click();

  setTimeout(() => {
    URL.revokeObjectURL(url);
  });
};

// Helper function to copy a given string to the clipboard
export const copyToClipboard = (vStr) => {
  navigator.clipboard.writeText(vStr);
};

// Helper function to pretty print a given string
// Separates string on capital letters and joins them again into a readable string
export const prettyPrint = (val) => {
  // Get a list of segments that start with a capital letter
  const capitals = val.match(/([A-Z]?[^A-Z]*)/g);

  // If the string is only capitals, return it as-is (acronyms)
  if (capitals.length === val.length + 1) {
    return val;
  }

  return capitals.slice(0, -1).join(" ").toLowerCase();
};
