import HowToRegIcon from "@mui/icons-material/HowToReg";
// import PasswordIcon from "@mui/icons-material/Password";
import { CircularProgress } from "@mui/material";
import { Avatar, Button, Stack, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { isValidEmail } from "../../common/validationHelpers";
import { useApi } from "../ApiProvider/ApiProvider";
import { useAppState } from "../AppStateProvider/AppStateProvider";

// Request password reset form helper component
export const PasswordResetRequestForm = (props) => {
  // Destruct props
  const { title, registration } = props;

  // Get notification context
  const { setToast } = useAppState();

  // Import reset / registration callbacks
  const { authApi } = useApi();

  // Set the active callback based on the registration flag
  const httpCallback = registration
    ? authApi.registrationRequest
    : authApi.passwordResetRequest;

  // Get notification context
  const navigate = useNavigate();

  // Form field touched state
  const [touched, setTouched] = useState({ email: false });

  // Loading state
  const [loading, setLoading] = useState(false);

  // Email field state
  const [email, setEmail] = useState("");

  // On-change handler
  const handleChange = (event, stateSetter) => {
    stateSetter(event.target.value);
  };

  // Handle a form submission
  const onSubmit = async () => {
    try {
      // Set the loading indicator
      setLoading(true);

      // Submit the request
      await httpCallback({ email: email });

      // Show the success notification
      navigate("/auth/notification", {
        replace: true,
        state: {
          title: "Request submitted.",
          message: `An email has been sent to ${email} - please check your inbox.`,
        },
      });
    } catch (e) {
      setToast({
        message: e && e.message ? e.message : "Action failed",
        type: "error", // success / error / warning / info / default
      });
    }

    // Reset the loading indicator
    setLoading(false);
  };

  // Form validation helper
  const isFormInvalid = () => {
    return isValidEmail(email);
  };

  return (
    <>
      <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
        <HowToRegIcon />
      </Avatar>
      <Typography component="h5" variant="h5">
        {title || "Submit email address"}
      </Typography>
      <Stack
        component="form"
        sx={{
          marginTop: 1,
          width: "30ch",
        }}
        spacing={2}
        autoComplete="off"
      >
        <TextField
          onBlur={() => {
            setTouched((v) => ({ ...v, email: true }));
          }}
          error={touched.email && !isValidEmail(email)}
          helperText="Please enter a valid email address"
          id="login-email"
          label="Email"
          variant="outlined"
          margin="normal"
          type={"email"}
          onChange={(e) => handleChange(e, setEmail)}
        />

        <Button
          variant="outlined"
          size="large"
          onClick={() => onSubmit()}
          disabled={loading || !isFormInvalid()}
        >
          {loading ? (
            <span>
              <CircularProgress size={15} />
            </span>
          ) : (
            <span>Send request</span>
          )}
        </Button>
        <Button
          variant="text"
          size="small"
          onClick={() => navigate("/auth/login", { replace: true })}
        >
          Return to login
        </Button>
      </Stack>
    </>
  );
};
