import "./Auth.css";
import { Navigate, useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import { AuthError } from "./AuthError";
import { PasswordResetForm } from "./PasswordResetForm";
import { PasswordResetRequestForm } from "./PasswordResetRequestForm";
import { LoginForm } from "./LoginForm";
import { AuthNotification } from "./AuthNotification";

// lodash debounce function for on-change

// Auth component
export default function Auth() {
  // Get url params
  const params = useParams();
  // Extract the wildcard if applicable
  const wildcard = params["*"] || "";

  // React based on URL params
  const getForm = (path) => {
    // Force lowercase
    path = path.toLowerCase() || "";

    // Redirect to /login if there is no sub-route
    if (path === "") return <Navigate to={"/auth/login"} replace />;
    // Display the registration form
    if (path === "register")
      return (
        <PasswordResetRequestForm title={"Registration request"} registration />
      );
    // Display the login form
    if (path === "login") return <LoginForm />;
    // Display the request password reset form
    if (path === "resetrequest")
      return <PasswordResetRequestForm title={"Request a password reset"} />;
    // Display the reset password form
    if (path === "reset") return <PasswordResetForm />;
    // Display the reset password form
    if (path === "notification") return <AuthNotification />;

    // If there are no matches above, display the error page
    return <AuthError />;
  };

  // Render the DOM
  return (
    <Box
      sx={{
        marginTop: 8,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {getForm(wildcard)}
    </Box>
  );
}
