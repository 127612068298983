import React from "react";
import { AuthError } from "../Auth/AuthError";

// An error boundary wrapper to catch all app errors and prevent a crash
export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.log(error);
    this.setState({
      errorMsg: error.message,
    });
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <AuthError
          title={this.state.errorMsg}
          message="An error was encountered during your last request"
        />
      );
    }

    return this.props.children;
  }
}
