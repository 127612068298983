import { httpPOST } from "./httpHelpers";

// Deploy Top Ten files
export async function httpDeployTopTen(payload) {
  return httpPOST(`/deployments/topten`, payload, "multipart/form-data");
}

// Deploy MTN Home files
export async function httpDeployMtnHome(payload) {
  return httpPOST(`/deployments/mtnhome`, payload, "multipart/form-data");
}
