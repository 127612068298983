import { CircularProgress } from "@mui/material";

// A helper component to display when loading pages
export function Loading() {
  // Render the DOM
  return (
    <div className="container flex-row flex-cc">
      <CircularProgress />
    </div>
  );
}
