import { useApi } from "../ApiProvider/ApiProvider";
import CrudGrid from "../CrudGrid/CrudGrid";

// This component manages reading and parsing data from the Top Ten endpoints
export function TopTenConfig() {
  // Get the feeds api
  const { analyticsApi } = useApi();

  // Validate any created or edited user model
  const validateModel = (model) => {
    // Create the validity flag
    var isValid = true;

    // Validate name
    if (!model.SiteName || model.SiteName.length < 1) isValid = false;

    // Return the result
    return isValid;
  };

  // Define the columns for the users grid
  const columns = [
    {
      field: "SiteId",
      headerName: "Site ID",
      flex: 2,
      minWidth: 100,
      editable: false,
    },
    {
      field: "SiteName",
      headerName: "Site Name",
      flex: 2,
      minWidth: 100,
      editable: true,
      preProcessEditCellProps: (params) => {
        const hasError = !params.props.value || params.props.value.length < 1;
        return { ...params.props, error: hasError };
      },
      validate: (v) => {
        return !!(v && v.length > 0);
      },
    },
    {
      field: "Assets",
      headerName: "Recent Assets",
      flex: 2,
      minWidth: 100,
      editable: false,
    },
  ];

  // Render the DOM
  return (
    <div className="container">
      {/* <h2>Top Ten CONFIG</h2> */}
      <CrudGrid
        customColumns={columns}
        fetchDataCb={analyticsApi.topTenSites}
        onSaveCb={analyticsApi.updateTopTenSites}
        validateRowCb={validateModel}
        disableExport
        canAddRecord={false}
      />
    </div>
  );
}
