// Email address validation helper
export const isValidEmail = (value) => {
  // This avoids "Unnecessary escape character: \.  no-useless-escape" linter errors
  //eslint-disable-next-line
  return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value);
};

// Password validation helper
// At least one upper case English letter, (?=.*?[A-Z])
// At least one lower case English letter, (?=.*?[a-z])
// At least one digit, (?=.*?[0-9])
// At least one special character, (?=.*?[#?!@$%^&*-])
// Minimum eight in length .{8,} (with the anchors)
export const isValidPassword = (value) => {
  // This avoids "Unnecessary escape character: \.  no-useless-escape" linter errors
  //eslint-disable-next-line
  return /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/.test(
    value
  );
};

// Validate the repeated password
export const isValidRepeatPassword = (password, repeatPassword) => {
  return repeatPassword === password && isValidPassword(password);
};

// IPV4 address validation helper
export const isValidIPV4 = (value) => {
  return /^((25[0-5]|(2[0-4]|1\d|[1-9]|)\d)\.?\b){4}$/.test(value);
};
