import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Divider,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import FolderZipIcon from "@mui/icons-material/FolderZip";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { useApi } from "../ApiProvider/ApiProvider";
import { useAppState } from "../AppStateProvider/AppStateProvider";
import { exportBlob } from "../../common/utility";
import moment from "moment";
import { useState } from "react";

// The top ten deployment component
export default function TopTenDeployment() {
  // Deployment API
  const { deploymentApi } = useApi();

  // Get notification context
  const { setToast } = useAppState();

  // Loading state
  const [loading, setLoading] = useState(false);

  // Form field touched state
  const [touched, setTouched] = useState({
    backgroundColour: false,
    displayQueueing: false,
  });

  // Background colour field state
  const [backgroundColour, setBackgroundColour] = useState("#ffcb05");
  // Display queueing field state
  const [displayQueueing, setDisplayQueueing] = useState(true);
  // Uploaded file state
  const [uploadedFile, setUploadedFile] = useState(null);

  // On-change handler
  const handleChange = (event, stateSetter) => {
    stateSetter(event.target.value);
  };

  // Handle the form submission
  const onSubmit = async () => {
    try {
      // Show the loading indicator
      setLoading(true);

      // Append  form fields
      const formData = new FormData();
      formData.append("backgroundColour", backgroundColour);
      formData.append("displayQueueing", displayQueueing);
      formData.append("file", uploadedFile);

      // Attempt to package the deployment
      const res = await deploymentApi.topTen(formData);

      // Download the response
      exportBlob(res, `${moment().unix()}_topten.zip`);

      // Show a toast
      setToast({
        message: "Upload successful",
        type: "success", // success / error / warning / info / default
      });
    } catch (e) {
      // Show a toast
      setToast({
        message: e && e.message ? e.message : "Action failed",
        type: "error", // success / error / warning / info / default
      });
    }

    // Hide the loading indicator
    setLoading(false);
  };

  // Form validation helper
  const isFormInvalid = () => {
    return backgroundColour?.length > 0 && uploadedFile?.name?.length > 0;
  };

  // Handle a file selection/upload
  const onChange = (event) => {
    // Exit if invalid
    if (event.target.files === null || event.target?.files?.length <= 0)
      return setToast({
        message: "Invalid file uploaded",
        type: "error", // success / error / warning / info / default
      });

    // Set the uploaded file
    setUploadedFile(event.target.files[0]);
  };
  // const onDrop = (event) => {
  //   console.log(`Drop ${event.dataTransfer.files[0].name}`);
  // };

  return (
    <div className="container" style={{ overflow: "auto" }}>
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
          <FolderZipIcon />
        </Avatar>
        <Typography component="h5" variant="h5" sx={{ mb: 2 }}>
          Top Ten Deployment
        </Typography>
        <span>
          Ensure uploaded zips follow this structure - "CTA" directory optional:
        </span>
        <div className="flex-row">
          <ul
            style={{
              textAlign: "left",
              flex: 1,
              borderRight: "1px solid rgba(255,255,255,0.25)",
              paddingRight: "15px",
            }}
          >
            <li>
              A
              <ul>
                <li>Frame_1</li>
                <li>.....</li>
                <li>Frame_5</li>
                <li>CTA</li>
              </ul>
            </li>
          </ul>
          <ul style={{ textAlign: "left", flex: 1 }}>
            <li>
              B
              <ul>
                <li>Frame_1</li>
                <li>.....</li>
                <li>Frame_5</li>
                <li>CTA</li>
              </ul>
            </li>
          </ul>
        </div>
        <Stack
          component="form"
          sx={{
            marginTop: 1,
            width: "30ch",
          }}
          spacing={2}
          autoComplete="off"
        >
          <TextField
            onBlur={() => {
              setTouched((v) => ({ ...v, backgroundColour: true }));
            }}
            error={touched.backgroundColour && backgroundColour?.length <= 0}
            id="topten-colour"
            label="Background colour hex"
            defaultValue={"#ffcb05"}
            variant="outlined"
            margin="normal"
            required
            onChange={(e) => handleChange(e, setBackgroundColour)}
          />

          <FormControlLabel
            // key={key}
            label={"Display queueing"}
            control={
              <Checkbox
                onBlur={() => {
                  setTouched((v) => ({ ...v, displayQueueing: true }));
                }}
                id="topten-queueing"
                checked={displayQueueing}
                onChange={(e) => setDisplayQueueing(e.target.checked)}
                disabled={loading}
                label="Display queueing"
              />
            }
          />
          <Typography
            sx={{ m: 1 }}
            variant="body1"
            component="span"
            align="left"
          >
            <Button variant="contained" component="label" sx={{ mr: 2 }}>
              Select file
              <input
                hidden
                type="file"
                accept=".zip,.rar,.7zip"
                multiple
                // onDrop={onDrop}
                onChange={onChange}
              />
            </Button>
            {uploadedFile?.name || "None"}
          </Typography>
          <Divider />

          <Button
            variant="outlined"
            size="large"
            onClick={() => onSubmit()}
            disabled={loading || !isFormInvalid()}
          >
            {loading ? (
              <span>
                <CircularProgress size={15} />
              </span>
            ) : (
              <span>Submit</span>
            )}
          </Button>
        </Stack>
      </Box>
    </div>
  );
}
