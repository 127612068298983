import "./App.css";
import {
  Route,
  Navigate,
  createRoutesFromElements,
  createBrowserRouter,
  RouterProvider,
  Outlet,
  useLoaderData,
  Await,
  defer,
  useAsyncError,
} from "react-router-dom";
import Auth from "./components/Auth/Auth";
import { Suspense, useMemo } from "react";
import { ApiProvider } from "./components/ApiProvider/ApiProvider";
import { Loading } from "./components/Loading/Loading";
import {
  BloombergIndices,
  BloombergNews,
  InvestecFocusFeeds,
} from "./components/BlobGrid/BlobGrid";
import { createTheme, ThemeProvider, useMediaQuery } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { UnprotectedLayout } from "./components/UnprotectedLayout/UnprotectedLayout";
import { ProtectedLayout } from "./components/ProtectedLayout/ProtectedLayout";
import { ErrorBoundary } from "./components/ErrorBoundary/ErrorBoundary";
import { AuthError } from "./components/Auth/AuthError";
import { httpBootstrap } from "./components/ApiProvider/authapi";
import { AppStateProvider } from "./components/AppStateProvider/AppStateProvider";
import { SnackbarProvider } from "notistack";
import { Deployments } from "./components/Deployments/Deployments";
import Users from "./components/Users/Users";
import Tokens from "./components/Tokens/Tokens";
import UserPermissions from "./components/Users/UserPermissions";
import TokenPermissions from "./components/Tokens/TokenPermissions";
import UserRoles from "./components/Users/UserRoles";
import TopTenDeployment from "./components/Deployments/TopTenDeployment";
import { TopTenAdminDashboard } from "./components/System/TopTenAdminDashboard";
import { ApiUsageDashboard } from "./components/Dashboard/ApiUsageDashboard";
import { InvestecDashboard } from "./components/Dashboard/InvestecDashboard";
import { TopTenDashboard } from "./components/Dashboard/TopTenDashboard";
import { System } from "./components/System/System";
import { TopTenConfig } from "./components/System/TopTenConfig";

// App entrypoint
export default function App() {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          // mode: prefersDarkMode ? "dark" : "light", // Fix this later
          mode: prefersDarkMode ? "dark" : "dark",
        },
      }),
    [prefersDarkMode]
  );
  // Render the DOM
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <ErrorBoundary>
          <RouterProvider router={router} />
        </ErrorBoundary>
      </ThemeProvider>
    </div>
  );
}

// Create the router
export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route
      element={<AuthLayout />}
      loader={() =>
        defer({
          bootstrapPromise: httpBootstrap().then(async (res) => {
            // Return decoded JSON if the call was successful
            if (res.ok) {
              return res.json();
            }

            // Get the error string
            const errorStr = await res.text();

            // Capitalise the first letter of the error string
            const formattedErr = `${res.status}: ${
              (errorStr.charAt(0) || "").toUpperCase() + errorStr.slice(1)
            }`;

            // Throw an error otherwise
            throw new Error(formattedErr);
          }),
        })
      }
    >
      <Route element={<UnprotectedLayout />}>
        <Route path="/auth/*" element={<Auth />} />
      </Route>
      <Route element={<ProtectedLayout />}>
        <Route path="dashboard">
          <Route path="" element={<Navigate to="topten" />} />
          <Route path="topten" element={<TopTenDashboard />} />
          <Route path="api" element={<ApiUsageDashboard />} />
          <Route path="investec" element={<InvestecDashboard />} />
        </Route>
        <Route path="users">
          <Route path="" element={<Users />} />
          <Route path="roles" element={<UserRoles />} />
          <Route path="permissions" element={<UserPermissions />} />
        </Route>
        <Route path="tokens">
          <Route path="" element={<Tokens />} />
          <Route path="permissions" element={<TokenPermissions />} />
        </Route>
        <Route path="deployments">
          <Route path="" element={<Deployments />} />
          <Route path="topten" element={<TopTenDeployment />} />
        </Route>
        <Route path="system">
          <Route path="" element={<System />} />
          <Route path="topten">
            <Route path="siteconfig" element={<TopTenConfig />} />
            <Route path="admindashboard" element={<TopTenAdminDashboard />} />
          </Route>
          <Route path="investec">
            <Route path="indices" element={<BloombergIndices />} />
            <Route path="news" element={<BloombergNews />} />
            <Route path="focusfeeds" element={<InvestecFocusFeeds />} />
          </Route>
        </Route>
      </Route>
      <Route exact path="*" element={<Navigate to="/auth" />} />
    </Route>
  )
);

// Auth layout parent route:
// Grants access to the auth context and animating route transitions
function AuthLayout() {
  // Use the router's loader data to access the logged in user object
  const { bootstrapPromise } = useLoaderData();

  // The DOM will default to a loading page while the auth API call completes
  return (
    <Suspense fallback={<Loading />}>
      <Await
        resolve={bootstrapPromise}
        errorElement={<AsyncError />}
        children={(response) => (
          <SnackbarProvider maxSnack={5}>
            <AppStateProvider>
              <ApiProvider data={response.Data}>
                <Outlet />
              </ApiProvider>
            </AppStateProvider>
          </SnackbarProvider>
        )}
      />
    </Suspense>
  );
}

// Async error helper component
const AsyncError = () => {
  const e = useAsyncError();
  return (
    <AuthError
      title={e.message}
      message="Unrecoverable error - please contact a site admin"
    />
  );
};
