import ErrorIcon from "@mui/icons-material/Error";
import { Avatar, Box, Button, Divider, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

// Error helper component
export const AuthError = (props) => {
  // Get notification context
  const navigate = useNavigate();
  // App location info
  const location = useLocation();

  // Destruct props
  const { title, message } = props;

  // Create a title and message state
  const errTitle = title || location?.state?.title || "404 - Not found";
  const errMessage = message || location?.state?.message || "";

  // Handle a notify and redirect click
  const handleRedirect = () => {
    navigate("/auth/login", { replace: true });
  };

  return (
    <Box
      sx={{
        marginTop: 8,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Avatar sx={{ m: 1, bgcolor: "error" }}>
        <ErrorIcon />
      </Avatar>
      <Typography component="h5" variant="h5" color={"error"} gutterBottom>
        <span className="capitalise-first">
          {errTitle || "Something went wrong!"}
        </span>
        <Divider orientation="horizontal" />
      </Typography>

      <Typography variant="body1" gutterBottom>
        {errMessage || ""}
      </Typography>

      <Typography variant="body1" gutterBottom>
        Use the button below to the login page.
      </Typography>
      <Button variant="text" size="large" onClick={() => handleRedirect()}>
        Return to login
      </Button>
    </Box>
  );
};
