import { httpGET, httpPUT, httpPOST } from "./httpHelpers";

// Submit a bootstrap request
export async function httpBootstrap() {
  return httpGET("/auth/bootstrap");
}

// Submit a registration request
export async function httpRegistrationRequest(payload) {
  return httpPUT("/auth/register", payload);
}

// Submit a password reset request
export async function httpPasswordResetRequest(payload) {
  return httpPUT("/auth/reset", payload);
}

// Submit a password reset
export async function httpResetPassword(payload) {
  return httpPOST("/auth/reset", payload);
}

// Submit a login request
export async function httpLogin(payload) {
  return httpPOST("/auth/login", payload);
}

// Submit a logout request
export async function httpLogout() {
  return httpPUT("/auth/logout");
}
